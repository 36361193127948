import React from "react";
import Hero from "../components/Hero";
import Topbar from "../components/Topbar";
//import Vendor from "../components/vendor"
import UniversitiesComp from "../components/universities";
import Recruitment from "../components/recruitments";
//import TestimonialComp from "../components/TestimonialComp"
// CaseStudies from "../components/CaseStudies";
import ContactForm from "../components/contactForm";
import Footer from "../components/footer";

function Clients() {
  return (
    <div>
      <Topbar />

      <Hero title="Clients" page="Clients" />

      <UniversitiesComp />

      <Recruitment />

      {/* <CaseStudies /> */}

      <ContactForm />

      <Footer />
    </div>
  );
}

export default Clients;
