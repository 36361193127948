import React from "react";
import { Link } from "react-router-dom";

const BusServComp = () => {
  return (
    <div>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">
              Business Services
            </h5>
            <h1 class="mb-0">Choose Your Career Path</h1>
          </div>
          <div class="row g-5">
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-1.jpg" alt="" />
                  <p class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4">
                    Business Analysis
                  </p>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">Business Analysis</h4>
                  <Link class="text-uppercase" to="/services">
                    Learn More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-2.jpg" alt="" />
                  <p
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    // href="#test"
                  >
                    Information Security Management
                  </p>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">Info Security Mgnt</h4>
                  <Link class="text-uppercase" to="/services">
                    Learn More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <br />
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-3.jpg" alt="" />
                  <p class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4">
                    Project Management
                  </p>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">Project Management</h4>
                  <Link class="text-uppercase" to="/services">
                    Learn More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-3.jpg" alt="" />
                  <p class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4">
                    Risk, Regulation and Compliance
                  </p>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">Risk, Regulation and Compliance</h4>
                  <Link class="text-uppercase" to="/services">
                    Learn More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-2.jpg" alt="" />
                  <p
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    // href="#test"
                  >
                    Information Security Management
                  </p>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">Information Security Management</h4>
                  <Link class="text-uppercase" to="/services">
                    Learn More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusServComp;
