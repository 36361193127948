import React from "react";

function Chooseus() {
  return (
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div class="container py-5">
        <div
          class="section-title text-center position-relative pb-3 mb-5 mx-auto"
          style={{ "max-width": "600px" }}
        >
          <h5 class="fw-bold text-primary text-uppercase">Why Choose Us</h5>
          <h1 class="mb-0">We Are Here to Grow Your Business Exponentially</h1>
        </div>
        <div class="row g-5">
          <div class="col-lg-4">
            <div class="row g-5">
              <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                <div
                  class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i class="fa fa-cubes text-white"></i>
                </div>
                <h4>Recruitment</h4>
                <p class="mb-0">
                  We have a robust recruitment process that allows us to
                  identify and attract the best IT and business professionals
                  from around the world.
                </p>
              </div>
              <div class="col-12 wow zoomIn" data-wow-delay="0.6s">
                <div
                  class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i class="fa fa-award text-white"></i>
                </div>
                <h4>Training</h4>
                <p class="mb-0">
                  We provide comprehensive training to all our professionals,
                  ensuring that they have the necessary skills and knowledge to
                  work effectively with our clients.
                </p>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4  wow zoomIn"
            data-wow-delay="0.9s"
            style={{ "min-height": "350px" }}
          >
            <div class="position-relative h-100">
              <img
                class="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.1s"
                src="img/feature.jpg"
                style={{ "object-fit": "cover" }}
                alt="something"
              />
            </div>
          </div>
          <div class="col-lg-4">
            <div class="row g-5">
              <div class="col-12 wow zoomIn" data-wow-delay="0.4s">
                <div
                  class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i class="fa fa-users-cog text-white"></i>
                </div>
                <h4>Deployment</h4>
                <p class="mb-0">
                  We deploy our professionals to work with our clients around
                  the world, providing them with the necessary support and
                  expertise to achieve their business goals.
                </p>
              </div>
              <div class="col-12 wow zoomIn" data-wow-delay="0.8s">
                <div
                  class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i class="fa fa-phone-alt text-white"></i>
                </div>
                <h4>Bridging the skills gap</h4>
                <p class="mb-0">
                  We are committed to bridging the digital skills gap by
                  providing access to skilled professionals for our clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chooseus;
