import React from "react";
import Carousel from "../components/Carousel";
import Topbar from "../components/Topbar";
import Facts from "../components/facts";
import AboutUSComp from "../components/AboutUs";
import Chooseus from "../components/ChooseUs";
import ServiceItems from "../components/ServiceItem";
import Footer from "../components/footer";
import Quote from "../components/quote";
import TeamComp from "../components/teamComp";
import CareerPath from "../components/CareerPath";
import BackToTop from "../components/backToTop";
import TestimonialComp from "../components/TestimonialComp";
import Vendor from "../components/vendor";

const home = () => {
  return (
    <div>
      <Topbar />
      <Carousel />
      <Facts />
      <AboutUSComp />
      <Chooseus />
      <ServiceItems />
      <Quote />
      <TestimonialComp />
      <TeamComp />
      <CareerPath title="CAREERS" />
      <Vendor />
      <Footer />
      <BackToTop />
    </div>
  );
};

export default home;
