import CareerComp from "../../components/CareerComp";
//import CaseStudies from "../../components/CaseStudies";
import Facts from "../../components/facts";
import Footer from "../../components/footer";
import GraduateApplication from "../../components/GraduateApplication";
import Hero from "../../components/Hero";
import Quote from "../../components/quote";
import Vendor from "../../components/vendor";

const Returners = () => {
  return (
    <>
      <Hero title="Returners Careers In Tech" page="Returners" />
      <GraduateApplication title="Returners Application" subTitle="returnees" />
      <CareerComp />
      <Facts />
      {/* <CaseStudies /> */}
      <Quote />
      <Vendor />
      <Footer />
    </>
  );
};

export default Returners;
