import React from "react";
import Hero from "../components/Hero";
import Topbar from "../components/Topbar";
import AboutUSComp from "../components/AboutUs";
import Footer from "../components/footer";
import BackToTop from "../components/backToTop";
import TeamComp from "../components/teamComp";

const AboutUs = () => {
  return (
    <div>
      <Topbar />
      <Hero title="About Us" page="Our-works" />
      <AboutUSComp />
      <TeamComp />
      {/* yet to add vendor.js troubleshooting-- */}
      <Footer />
      <BackToTop />
    </div>
  );
};

export default AboutUs;
