import React from "react";
import { Link } from "react-router-dom";

const TechnicalServicesComp = () => {
  return (
    <div>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">
              Technical Services
            </h5>
            <h1 class="mb-0">Oanwtech’s Technical Services</h1>
          </div>
          <div class="row g-6">
            <div class="col-lg-12">
              <div class="row g-5">
                <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <h4>Oanwtech’s Technical Services</h4>
                  <p class="mb-0">
                    Oanwtech’s technical consultants work within our clients IT
                    teams to support complex projects and to provide solutions
                    to business problems. Our consultants are trained to the
                    highest standards and work across the software development
                    lifecycle including design, development, testing, analysis
                    and maintenance as well as providing ongoing support.
                  </p>
                  <br />
                  <p class="mb-0">
                    Our people combine technical excellence with strong
                    interpersonal skills and work in some of the most demanding
                    and challenging environments. We provide ongoing
                    professional and technical support for all of our
                    consultants to help with their personal development and to
                    ensure we offer a seamless service to our clients.
                  </p>
                  <br />
                  <p class="mb-0">
                    Our diverse talent pools, combined with the ability to
                    transfer Oanwtech’s consultants to permanent in-house
                    resources, means our clients can benefit from a range of
                    experiences and technical skills in the creation of their
                    own balanced teams.
                  </p>
                  <br />
                  <Link
                    to="/services"
                    class="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                    data-wow-delay="0.9s"
                  >
                    <i class="bi bi-arrow-right"></i>Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalServicesComp;
