const PrivacyComp = () => {
  return (
    <>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ "max-width": "600px;" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Privacy</h5>
            <h2 class="mb-0">
              At Oanwtech, we take your privacy seriously and are committed to
              protecting your personal information.
              <br /> This Privacy Content Page explains how we collect, use, and
              protect your personal information.
            </h2>
          </div>
          <div class="row g-6">
            <div class="col-lg-12">
              <div class="row g-5">
                <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <h4>About Us</h4>
                  <p class="mb-0">
                    Oanwtech is an IT & Digital Solution company providing
                    services to individuals and businesses. We are committed to
                    protecting your privacy and ensuring that your personal
                    information is handled in accordance with data protection
                    laws.
                  </p>
                  <br />
                  <h4>What Information Do We Collect About You</h4>
                  <p class="mb-0">
                    We may collect personal information about you when you
                    interact with us, such as when you visit our website, use
                    our services, or contact us. The types of personal
                    information we may collect include your name, email address,
                    postal address, phone number, job title, and company name.
                  </p>
                  <br />
                  <h4>How Will We Use The Information About You?</h4>
                  <br />
                  <p class="mb-0">
                    We may use the personal information we collect for the
                    following purposes:
                  </p>
                  <br />
                  <h6 class="mb-4">
                    <i class="fa fa-reply text-primary me-3"></i>To provide and
                    improve our services to you.
                  </h6>
                  <h6 class="mb-4">
                    <i class="fa fa-reply text-primary me-3"></i>To communicate
                    with you about our services, promotions, and events.
                  </h6>
                  <p class="mb-0">
                    <h6 class="mb-4">
                      <i class="fa fa-reply text-primary me-3"></i>To process
                      your orders and payments.
                    </h6>
                    <h6 class="mb-4">
                      <i class="fa fa-reply text-primary me-3"></i>To respond to
                      your inquiries and requests.
                    </h6>
                    <h6 class="mb-4">
                      <i class="fa fa-reply text-primary me-3"></i>To comply
                      with legal and regulatory requirements.
                    </h6>
                    <h6 class="mb-4">
                      <i class="fa fa-reply text-primary me-3"></i>Access to
                      Your Information and Correction
                    </h6>
                  </p>
                  <h4>Access to Your Information and Correction</h4>
                  <p class="mb-0">
                    You have the right to access, correct, or delete your
                    personal information held by us. If you would like to
                    exercise any of these rights, please contact us using the
                    details provided below.
                  </p>
                  <br />
                  <h4>How Long Do We Keep Your Data?</h4>
                  <p class="mb-0">
                    We will only retain your personal information for as long as
                    necessary to fulfill the purposes for which it was
                    collected, including any legal, accounting, or reporting
                    requirements.
                  </p>
                  <br />
                  <h4>Automated Decision Making</h4>
                  <p class="mb-0">
                    We do not use automated decision making processes that
                    produce legal or similarly significant effects on
                    individuals.
                  </p>
                  <br />
                  <h4>Data Transfers Outside of the EU</h4>
                  <p class="mb-0">
                    We may transfer your personal information to countries
                    outside the European Union (EU) in order to provide our
                    services to you. If we do, we will take appropriate steps to
                    ensure that your personal information is protected in
                    accordance with applicable data protection laws.
                  </p>
                  <br />
                  <h4>Cookies</h4>
                  <p class="mb-0">
                    We use cookies on our website to improve your browsing
                    experience and to analyze how our website is used. For more
                    information about cookies and how to manage them, please see
                    our Cookie Policy.
                  </p>
                  <br />
                  <h4>
                    What Can You Do If You Are Not Happy with the Way We Have
                    Processed Your Information?
                  </h4>
                  <p class="mb-0">
                    If you are not happy with the way we have processed your
                    personal information, please contact us using the details
                    provided below. You also have the right to lodge a complaint
                    with a supervisory authority.
                  </p>
                  <br />
                  <h4>Changes to Our Privacy Policy</h4>
                  <p class="mb-0">
                    If you are not happy with the way we have processed your
                    personal information, please contact us using the details
                    provided below. You also have the right to lodge a complaint
                    with a supervisory authority.
                  </p>
                  <br />
                  <h4>Contact Us</h4>
                  <p class="mb-0">
                    If you have any questions or concerns about our use of your
                    personal information, please contact us at
                    privacy@oanwtech.com.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyComp;
