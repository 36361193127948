import React from "react";
import Topbar from "../../components/Topbar";
import Hero from "../../components/HeroServices";
import TechnicalServicesComp from "../../components/technicalServicesComp";
import ContactBtn from "../../components/ContactBtn";
import MoreCaseStudies from "../../components/moreCaseStuides";
import OtherServices from "../../components/otherServices";
import CareerPath from "../../components/CareerPath";
import Quote from "../../components/quote";
import Footer from "../../components/footer";
import BackToTop from "../../components/backToTop";
import TestimonialComp from "../../components/TestimonialComp";
import Vendor from "../../components/vendor";

const TechnicalServices = () => {
  return (
    <div>
      <Topbar />
      <Hero
        title="Technical Services"
        extras={
          <center>
            <p
              // href="brochure.pdf"
              class="btn btn-primary py-10 px-5 mt-3 wow zoomIn"
              data-wow-delay="0.9s"
            >
              View our consultant experience brochure
            </p>
          </center>
        }
        main="services"
        page="Technical services"
      />
      <TechnicalServicesComp />
      <ContactBtn />
      <CareerPath title="Technical Services" />
      <OtherServices
        titleOne="Business Services"
        miniTitleOne="Business"
        linkOne="/business-services"
        titleTwo="Tailored Services"
        miniTitleTwo="Tailored"
        linkTwo="/tailoredservices"
        titleThree="Alliances"
        miniTitleThree="Alliances"
        linkThree="/alliances"
        titleFour="Case Studies"
        miniTitleFour="Case Studies"
        linkFour="/casestudy"
      />
      <TestimonialComp />
      <MoreCaseStudies />
      <Quote />
      <Vendor />
      <Footer />
      <BackToTop />
    </div>
  );
};

export default TechnicalServices;
