import React from 'react'

function BlogSearchBar(){

    return (

        <>
        
        <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
            <div class="input-group">
                <input type="text" class="form-control p-3" placeholder="Keyword"/>
                <button class="btn btn-primary px-4"><i class="bi bi-search"></i></button>
            </div>
        </div>
        
        </>

    )

}

export default BlogSearchBar