import Topbar from "../components/Topbar";
import Hero from "../components/HeroServices";
import AccessibilityComp from "../components/Accessibility";
import Vendor from "../components/vendor";
import Footer from "../components/footer";

const Accessibility = () => {
  return (
    <>
      <Topbar />
      <Hero title="Accessibility In Tech Industry" page="accessibility" />
      <AccessibilityComp />
      <Vendor />
      <Footer />
    </>
  );
};

export default Accessibility;
