import React from "react";
import BackToTop from "../../components/backToTop";
import CaseStudyComp from "../../components/CaseStudyComp";
import ContactBtn from "../../components/ContactBtn";
import Footer from "../../components/footer";
import Hero from "../../components/HeroServices";
import MoreCaseStudies from "../../components/moreCaseStuides";
import OtherServices from "../../components/otherServices";
import Quote from "../../components/quote";
import TestimonialComp from "../../components/TestimonialComp";
import Topbar from "../../components/Topbar";
import Vendor from "../../components/vendor";

const CaseStudy = () => {
  return (
    <div>
      <Topbar />
      <Hero title="Case Studies" main="services" page="Case Studies" />
      <CaseStudyComp />
      <ContactBtn />
      <OtherServices
        titleOne="Business Services"
        miniTitleOne="Business"
        linkOne="/business-services"
        titleTwo="Technical Services"
        miniTitleTwo="Technical"
        linkTwo="/technicalservices"
        titleThree="Alliances"
        miniTitleThree="Alliances"
        linkThree="/alliances"
        titleFour="Tailored Services"
        miniTitleFour="Tailored"
        linkFour="/tailoredservices"
      />
      <TestimonialComp />
      <MoreCaseStudies />
      <Quote />
      <Vendor />
      <Footer />
      <BackToTop />
    </div>
  );
};

export default CaseStudy;
