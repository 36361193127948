import React from "react";
import BackToTop from "../components/backToTop";
import Footer from "../components/footer";
import Hero from "../components/Hero";
import ServiceItems from "../components/ServiceItem";
import TestimonialComp from "../components/TestimonialComp";
import Topbar from "../components/Topbar";
import Vendor from "../components/vendor";

const Services = () => {
  return (
    <div>
      <Topbar />
      <Hero title="Services" page="Services" />
      <ServiceItems />
      <TestimonialComp />
      <Vendor />
      <Footer />
      <BackToTop />
    </div>
  );
};
export default Services;
