import React from "react";
import { Link } from "react-router-dom";

const CareerPath = (props) => {
  return (
    <div>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">{props.title}</h5>
            <h1 class="mb-0">Choose Your Career Path</h1>
          </div>
          <div class="row g-5">
            <div class="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-1.jpg" alt="hbdhbvcdh" />
                  <Link
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    to="/graduate"
                  >
                    Graduates
                  </Link>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">Graduate Opportunities</h4>
                  <p>
                    Oanwtech provides a range of career paths for graduates
                    looking to start their career in the IT and digital
                    industry. This could include positions such as software
                    engineers, project managers, UX designers, and more
                  </p>
                  <Link class="text-uppercase" to="/graduate">
                    Learn More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-2.jpg" alt="hbdhbvcdh" />
                  <Link
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    to="/undergraduate"
                  >
                    Undergraduates
                  </Link>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">Undergraduate Placement</h4>
                  <p>
                    Oanwtech offers opportunities for undergraduate students to
                    gain valuable experience through internships or work
                    placements. This could be a great way for students to
                    develop their skills and knowledge while also gaining
                    exposure to real-world projects and challenges
                  </p>
                  <Link class="text-uppercase" to="/undergraduate">
                    Learn More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <br />
            <div class="col-lg-6 wow slideInUp" data-wow-delay="0.9s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-3.jpg" alt="hbdhbvcdh" />
                  <Link
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    to="/exforces"
                  >
                    Ex-Forces
                  </Link>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">Suport for Ex-Forces</h4>
                  <p>
                    Oanwtech recognizes the unique skills and experience that
                    ex-forces personnel bring to the workplace. As such, the
                    company may offer tailored support and training programs to
                    help ex-forces personnel transition into civilian careers in
                    IT and digital.
                  </p>
                  <Link class="text-uppercase" to="/exforces">
                    Learn More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-6 wow slideInUp" data-wow-delay="0.9s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-3.jpg" alt="hbdhbvcdh" />
                  <Link
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    to="/returners"
                  >
                    Returners
                  </Link>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">Returners Initiatives</h4>
                  <p>
                    Oanwtech may have initiatives in place to support
                    individuals who are returning to work after an extended
                    break. This could include flexible working arrangements,
                    mentoring programs, and targeted training and development
                    opportunities
                  </p>
                  <Link class="text-uppercase" to="/returners">
                    Read More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerPath;
