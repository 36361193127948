import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const TestimonialComp = () => {
  return (
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div class="container py-5">
        <div
          class="section-title text-center position-relative pb-3 mb-4 mx-auto"
          style={{ "max-width": "600px" }}
        >
          <h5 class="fw-bold text-primary text-uppercase">Testimonial</h5>
          <h1 class="mb-0">
            What Our University Partners Say About Our Digital Services
          </h1>
        </div>
        <OwlCarousel className="owl-theme" loop margin={4} nav>

          <div class="card">
            <div class="card-body">

              <div class="d-flex align-items-center">
                <img
                  class="rounded-circle"
                  alt="jjdj"
                  src="img/testimonial-1.jpg"
                  style={{ width: "60px", height: "60px" }}
                />

              </div>

              <div class="">
                <h4 class="">Sara</h4>

                <p><small class="text-uppercase">Entrepreneur</small></p>

                <small>
                  <p>
                    Their team is absolutely fantastic!
                  </p>
                </small>

              </div>

            </div>
          </div>

          <div class="card">
            <div class="card-body">

              <div class="d-flex align-items-center">
                <img
                  class="rounded-circle"
                  alt="jjdj"
                  src="img/testimonial-2.jpg"
                  style={{ width: "60px", height: "60px" }}
                />

              </div>

              <div class="">
                <h4 class="">Joe</h4>

                <p><small class="text-uppercase">Business Owner</small></p>

                <small>
                  <p>
                    I'm so pleased with their work
                  </p>
                </small>

              </div>

            </div>
          </div>

          <div class="card">
            <div class="card-body">

              <div class="d-flex align-items-center">
                <img
                  class="rounded-circle"
                  alt="jjdj"
                  src="img/testimonial-3.jpg"
                  style={{ width: "60px", height: "60px" }}
                />

              </div>

              <div class="">
                <h4 class="">James</h4>

                <p><small class="text-uppercase">Business Owner</small></p>

                <small>
                  <p>
                    They're highly knowledgeable.
                  </p>
                </small>

              </div>

            </div>
          </div>

          <div class="card">
            <div class="card-body">

              <div class="d-flex align-items-center">
                <img
                  class="rounded-circle"
                  alt="jjdj"
                  src="img/testimonial-4.jpg"
                  style={{ width: "60px", height: "60px" }}
                />

              </div>

              <div class="">
                <h4 class="">Judith</h4>

                <p><small class="text-uppercase">Entrepreneur</small></p>

                <small>
                  <p>
                    So perfect in customer service
                  </p>
                </small>

              </div>

            </div>
          </div>

        </OwlCarousel>
      </div>
    </div>
  );
};

export default TestimonialComp;
