import React from "react";
import { Link } from "react-router-dom";

const AboutUSComp = () => {
  return (
    <div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  About Us
                </h5>
                <h1 className="mb-0">
                  The Best IT Solution With 10 Years of Experience
                </h1>
              </div>
              <p className="mb-4">
                At Oanwtech, we're dedicated to creating careers for our
                employees and helping our clients achieve their goals. We're
                committed to staying at the forefront of technology and
                providing our clients with the best possible service.
              </p>
              <div class="row g-0 mb-3">
                <div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 class="mb-3">
                    <i class="fa fa-check text-primary me-3"></i>Career
                    Development
                  </h5>
                  <h5 class="mb-3">
                    <i class="fa fa-check text-primary me-3"></i>Technological
                    Innovation
                  </h5>
                </div>
                <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 class="mb-3">
                    <i class="fa fa-check text-primary me-3"></i>Proven Track
                    Record
                  </h5>
                  <h5 class="mb-3">
                    <i class="fa fa-check text-primary me-3"></i>Continuous
                    Improvement
                  </h5>
                </div>
                <div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 class="mb-3">
                    <i class="fa fa-check text-primary me-3"></i>Experienced
                    Team
                  </h5>
                  <h5 class="mb-3">
                    <i class="fa fa-check text-primary me-3"></i>Client-Centric
                    approach
                  </h5>
                </div>
                <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 class="mb-3">
                    <i class="fa fa-check text-primary me-3"></i>24/7 Support
                  </h5>
                  <h5 class="mb-3">
                    <i class="fa fa-check text-primary me-3"></i>Customer
                    satisfaction
                  </h5>
                </div>
              </div>
              <div
                class="d-flex align-items-center mb-4 wow fadeIn"
                data-wow-delay="0.6s"
              >
                <div
                  class="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i class="fa fa-phone-alt text-white"></i>
                </div>
                <div class="ps-4">
                  <h5 class="mb-2">Call to ask any question</h5>
                  <h4 class="text-primary mb-0">437-429-7769</h4>
                </div>
              </div>
              <Link
                to="/quote"
                className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                data-wow-delay="0.9s"
              >
                Request A Quote
              </Link>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  alt="about-img"
                  src="img/about.jpg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUSComp;
