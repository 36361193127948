import CareerComp from "../../components/CareerComp";
//import CaseStudies from "../../components/CaseStudies";
import Facts from "../../components/facts";
import Footer from "../../components/footer";
import GraduateApplication from "../../components/GraduateApplication";
import Hero from "../../components/Hero";
import Quote from "../../components/quote";
import Vendor from "../../components/vendor";

const ExForces = () => {
  return (
    <>
      <Hero title="Ex-forces Careers In Tech" page="Ex-forces" />
      <GraduateApplication title="Ex-forces Application" subTitle="Ex-forces" />
      <CareerComp />
      <Facts />
      {/* <CaseStudies /> */}
      <Quote />
      <Vendor />
      <Footer />
    </>
  );
};

export default ExForces;
