import React from "react";
import Hero from "../components/Hero";
import Topbar from "../components/Topbar";
import Vendor from "../components/vendor";
import UniversitiesComp from "../components/universities";
import Recruitment from "../components/recruitments";
import TestimonialComp from "../components/TestimonialComp";
//import CaseStudies from "../components/CaseStudies";
import ContactForm from "../components/contactForm";
import Footer from "../components/footer";

function Universities() {
  return (
    <div>
      <Topbar />

      <Hero title="Supporting Our Universities Partners" page="Universities" />

      <Vendor />

      <UniversitiesComp />

      <Recruitment />

      <TestimonialComp />

      {/* <CaseStudies /> */}

      <ContactForm />

      <Footer />
    </div>
  );
}

export default Universities;
