import Topbar from "../components/Topbar";
import Hero from "../components/HeroServices";
import PrivacyComp from "../components/PrivacyComp";
import Vendor from "../components/vendor";
import Footer from "../components/footer";

const Privacy = () => {
  return (
    <>
      <Topbar />
      <Hero title="Privacy Policy" page="Privacy-Policy" />
      <PrivacyComp />
      <Vendor />
      <Footer />
    </>
  );
};

export default Privacy;
