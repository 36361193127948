import React from 'react';

function Education(){

    return (

        <>
        
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{"maxWidth":"600px"}}>
                <h5 class="fw-bold text-primary text-uppercase">Education</h5>
                <h1 class="mb-0">Our Work with <br/>the Community</h1>
            </div>
            <div class="row g-6">
                <div class="col-lg-12">
                    <div class="row g-5">
                        <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                            
                            <h4>We Recruit</h4>
                            <p class="mb-0">We understand the importance of equipping students with the skills to enjoy and engage with STEM fields at an early age. We are proud to work with schools throughout the UK to cultivate the technologists of tomorrow, supporting our purpose of creating and inspiring exciting careers that shape our digital future. </p><br />
							<p class="mb-0">Some of our work has included:</p><br/>
							<h6 class="mb-4"><i class="fa fa-reply text-primary me-3"></i>Partnership with The Harris Federation providing a tailored programme to allow children to experience the commercial enviroment of FDM</h6>
							<h6 class="mb-4"><i class="fa fa-reply text-primary me-3"></i>Regular insight days in our offices for groups of school children</h6>
							<h6 class="mb-4"><i class="fa fa-reply text-primary me-3"></i>Coding workshops to give children an insight into a career in tech using innovative programmes such as Sonic Pi</h6>
							<h6 class="mb-4"><i class="fa fa-reply text-primary me-3"></i>Deliver Careers Lab sessions in schools, bringing business insight to equip young people with the inspiration, knowledge and skills they need to succeed</h6>
							<h6 class="mb-4"><i class="fa fa-reply text-primary me-3"></i>Sponsorship of the Digital Skills category at the TeenTech Awards since 2017</h6>
							<a class="btn btn-lg btn-primary rounded" href="our-services.html">
                            <i class="bi bi-arrow-right"></i>Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{"maxWidth": "600px"}}>
                <h5 class="fw-bold text-primary text-uppercase">Education</h5>
                <h1 class="mb-0">Harris Federation Case Study</h1>
            </div><br/>
			<center><a href="index.html" class="navbar-brand p-0"><img class="w-2" style={{"height": "550px", "width": "1000px"}} 
            src="img/carousel-1.jpg" alt=" file"/>
            </a></center>
		</div>
        </div>

        </>

    )

}

export default Education