/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import logo from "../img/oanwtech-logo-1204.png";

const Nav = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark ">
        <div className="container">
          <Link to="/" className="navbar-brand m-0 " style={{ color : "#06A3DA",fontSize : "35px"  }}>
            
              <img className="me-2" src={logo} alt="Logo" />
              <b >OanwTech</b>
            
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <Link to="/" className="nav-item nav-link">
                Home
              </Link>

              {/* Our Works */}
              <div className="nav-item dropdown">
                <Link
                  to="/aboutus"
                  className="nav-item nav-link"
                  data-bs-toggle="dropdown"
                >
                  Works
                </Link>
                <div className="dropdown-menu m-0">
                  <Link to="/clients" className="dropdown-item">
                    Clients
                  </Link>
                  <Link to="/universities" className="dropdown-item">
                    Universities
                  </Link>
                  <Link to="/community" className="dropdown-item">
                    Community & Services
                  </Link>
                  {/* <Link to="/Awards" className="dropdown-item">
                  Awards
                </Link>
                 */}
                </div>
              </div>

              {/* Services */}
              <div className="nav-item dropdown">
                <Link
                  to="/sevices"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Services
                </Link>
                <div className="dropdown-menu m-0">
                  <Link to="/business-services" className="dropdown-item">
                    Business Services
                  </Link>
                  <Link to="/technicalservices" className="dropdown-item">
                    Technical Services
                  </Link>
                  <Link to="/tailoredservices" className="dropdown-item">
                    Tailored Services
                  </Link>
                  <Link to="/alliances" className="dropdown-item">
                    Alliances
                  </Link>
                  <Link to="/casestudy" className="dropdown-item">
                    Case Studies
                  </Link>
                </div>
              </div>

              {/* Career */}
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Careers
                </Link>
                <div className="dropdown-menu m-0">
                  <Link to="/undergraduate" className="dropdown-item">
                    Undergraduates
                  </Link>
                  <Link to="/graduate" className="dropdown-item">
                    Graduate
                  </Link>
                  <Link to="/returners" className="dropdown-item">
                    Returners
                  </Link>
                  <Link to="/exforces" className="dropdown-item">
                    Ex-forces
                  </Link>
                </div>
              </div>

              {/* Investors */}
              {/* <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Investors
              </Link>
              <div className="dropdown-menu m-0">
                <Link to="/visoan" className="dropdown-item">
                  Visoan
                </Link>
                <Link to="/oanwave" className="dropdown-item">
                  Oanwave
                </Link>
              </div>
            </div> */}
              <Link to="/blog" className="nav-item nav-link">
                Blog
              </Link>
              <Link to="/contact" className="nav-item nav-link">
                Contact
              </Link>
            </div>
            <button
              type="button"
              className="btn text-primary ms-3"
              data-bs-toggle="modal"
              data-bs-target="#searchModal"
            >
              <i className="fa fa-search"></i>
            </button>
            <Link to="/quote" className="btn btn-primary py-2 px-4 ms-3">
              Get A Free Quote
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
