import React from "react";
import { Link } from "react-router-dom";

const CaseStudyComp = () => {
  return (
    <div>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">
              How Can Oanwtech Help
            </h5>
            <h1 class="mb-0">
              Learn How Oanwtech <br />
              can help your Business
            </h1>
          </div>
          <div class="row g-6">
            <div class="col-lg-12">
              <div class="row g-5">
                <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <p class="mb-0">
                    At Oanwtech, we understand that technology is the backbone
                    of every business, and having the right professionals with
                    the right skills is crucial to success.{" "}
                  </p>
                  <br />
                  <p class="mb-0">
                    That's why we help organisations around the globe fill their
                    technology skills gaps by providing a pipeline of technology
                    and business professionals.
                  </p>
                  <br />
                  <p class="mb-0">
                    Don't just take our word for it though. Check out some of
                    the case studies below to see the difference Oanwtech has
                    made for our clients throughout the world.
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Technical Services Displays Start  */}
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">
              Technical Services
            </h5>
            <h1 class="mb-0">Choose Your Career Path</h1>
          </div>
          <div class="row g-5">
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-1.jpg" alt="" />
                  <p class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4">
                    Provision of Skilled Professionals
                  </p>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">Exxperia Inc.</h4>
                  <p class="mb-0">
                    This client was struggling to keep up with the rapidly
                    evolving technology landscape. They lacked the in-house
                    expertise needed to stay ahead of the competition. Oanwtech
                    was able to provide a team of skilled professionals who were
                    able to implement cutting-edge technologies and processes.
                    As a result, Company A saw a significant increase in
                    efficiency and productivity, leading to a boost in revenue
                  </p>
                  <Link class="text-uppercase" to="/casestudy">
                    View Case Study <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-2.jpg" alt="" />
                  <p
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    // "#test"
                  >
                    High Turnover Rate
                  </p>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">MAx Group of Company</h4>
                  <p class="mb-0">
                    This client was experiencing a high turnover rate among
                    their technology staff. They were having trouble finding and
                    retaining talented individuals who could help drive the
                    company forward. Oanwtech stepped in and provided a steady
                    stream of skilled professionals who were able to integrate
                    seamlessly into the existing team. As a result, Company B
                    was able to reduce turnover, improve employee satisfaction,
                    and increase their overall output.
                  </p>
                  <Link class="text-uppercase" to="/casestudy">
                    View Case Study <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <br />
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-3.jpg" alt="" />
                  <p
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    // href="#test"
                  >
                    Finding the Right Expertise
                  </p>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">Turpido</h4>
                  <p class="mb-0">
                    This client was facing a particularly challenging project
                    that required a unique set of skills. They were having
                    trouble finding someone who had the right expertise and
                    experience. Oanwtech was able to provide a specialist who
                    was able to quickly get up to speed and make a significant
                    contribution to the project. This allowed Company C to meet
                    their deadline and exceed their expectations.
                  </p>
                  <Link class="text-uppercase" to="/casestudy">
                    View Case Study <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-3.jpg" alt="" />
                  <p
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    // href="#test"
                  >
                    Digital Transformation for a Retail Giant
                  </p>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">Retail GI-ant</h4>
                  <p class="mb-0">
                    One of our clients, a large retail giant, was struggling
                    with their digital transformation efforts. They had a great
                    team of employees, but they lacked the technical expertise
                    needed to take their business to the next level. That's
                    where we came in. By and large, they saw a significant
                    increase in revenue and were able to compete more
                    effectively with other retailers in the market.
                  </p>
                  <Link class="text-uppercase" to="/casestudy">
                    View Case Study <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-2.jpg" alt="" />
                  <p
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    // href="#test"
                  >
                    Cloud Migration for a Healthcare
                  </p>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">FeelAtHome Maternity</h4>
                  <p class="mb-0">
                    A healthcare provider was struggling with their legacy IT
                    systems. Our team of cloud migration experts worked closely
                    with the client's internal team to understand their unique
                    needs and was able to reduce their IT costs, improve their
                    security posture, and provide better services to their
                    patients.
                  </p>
                  <Link class="text-uppercase" to="/casestudy">
                    View Case STudy<i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-2.jpg" alt="" />
                  <p
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    // href="#test"
                  >
                    Data Analytics for a Fin. Inst.
                  </p>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">MOVE-ee Business Institute</h4>
                  <p class="mb-0">
                    Another client, a large financial institution, was
                    struggling to make sense of their vast amounts of data. They
                    had a team of analysts, but they lacked the technical
                    skills. Our team of data analytics experts worked closely
                    with theirs, as a result, the client was able to make more
                    informed business decisions, reduce their risk exposure, and
                    improve their overall profitability.
                  </p>
                  <Link class="text-uppercase" to="/casestudy">
                    Learn More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <center>
        <h4 class="mb-3">
          For more information or to learn how <br />
          Oanwtech can assist your team, <br />
          get in touch with us today.
        </h4>
      </center>
    </div>
  );
};

export default CaseStudyComp;
