import Topbar from "../components/Topbar";
import Hero from "../components/HeroServices";
import TermsAndConditionsComp from "../components/Terms";
import Vendor from "../components/vendor";
import Footer from "../components/footer";

const TermsAndConditions = () => {
  return (
    <>
      <Topbar />
      <Hero title="Terms And Condition" page="Terms-And-Condition" />
      <TermsAndConditionsComp />
      <Vendor />
      <Footer />
    </>
  );
};

export default TermsAndConditions;
