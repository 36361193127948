import React from "react";

function UniversitiesComp() {
  return (
    <>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ "max-width": "600px;" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Universities</h5>
            <h1 class="mb-0">
              Over 60 Partnerships <br />
              Across Africa
            </h1>
          </div>
          <div class="row g-6">
            <div class="col-lg-12">
              <div class="row g-5">
                <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <h4>We Recruit</h4>
                  <p class="mb-0">
                    We support universities’ employability strategies by
                    providing programmes that prepare their graduates for the
                    corporate world, helping to hone the skills they need to
                    build meaningful and rewarding long-term careers.{" "}
                  </p>
                  <br />
                  <p class="mb-0">Oanwtech’s activities include:</p>
                  <br />
                  <h6 class="mb-4">
                    <i class="fa fa-reply text-primary me-3"></i>Curriculum
                    projects with first, second and third year students to
                    develop skills for real life business challenges
                  </h6>
                  <h6 class="mb-4">
                    <i class="fa fa-reply text-primary me-3"></i>Curriculum
                    projects with first, second and third year students to
                    develop skills for real life business challenges
                  </h6>
                  <p class="mb-0">
                    <h6 class="mb-4">
                      <i class="fa fa-reply text-primary me-3"></i>Digital
                      Bootcamps, including sessions around Agile, coding skills
                      and Excel, to help students and graduates from a non-STEM
                      background develop their digital skills
                    </h6>
                    <h6 class="mb-4">
                      <i class="fa fa-reply text-primary me-3"></i>Virtual
                      workshops to provide technical expertise and industry
                      insights FDM Tech Week featuring a series of events on a
                      broad range of hot topics in tech
                    </h6>
                    <h6 class="mb-4">
                      <i class="fa fa-reply text-primary me-3"></i>Diversity &
                      Inclusion focussed events Promoting opportunities for
                      women in STEM via bespoke workshops and initiatives
                    </h6>
                    <h6 class="mb-4">
                      <i class="fa fa-reply text-primary me-3"></i>Paid student
                      internships and year-long placement programmes
                    </h6>
                  </p>
                  <a
                    class="btn btn-lg btn-primary rounded"
                    href="our-services.html"
                  >
                    <i class="bi bi-arrow-right"></i>Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UniversitiesComp;
