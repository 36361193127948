import React from "react";
import { Link } from "react-router-dom";

const BusinessServicesComp = () => {
  return (
    <div>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">
              Business Services
            </h5>
            <h1 class="mb-0">Oanwtech’s Business Consultants</h1>
          </div>
          <div class="row g-6">
            <div class="col-lg-12">
              <div class="row g-5">
                <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <h4>Oanwtech’s Business Consultants</h4>
                  <p class="mb-0">
                    Oanwtech’s business consultants work with clients on
                    operational and business transformation programmes to help
                    with both the implementation and delivery of projects, as
                    well as providing ongoing support.
                  </p>
                  <br />
                  <p class="mb-0">
                    Our people combine technical excellence with strong
                    interpersonal skills and work in some of the most demanding
                    and challenging environments. We provide ongoing
                    professional and technical support for all of our
                    consultants to help with their personal development and to
                    ensure we provide a seamless service to our clients.
                  </p>
                  <br />
                  <p class="mb-0">
                    Our diverse talent pools, combined with the ability to
                    transfer Oanwtech’s consultants to permanent in-house resources
                    means our clients can benefit from a range of experiences
                    and technical skills in the creation of their own balanced
                    teams.
                  </p>
                  <br />
                  <p class="mb-0">
                    Our Advanced Ex-Forces Programme transitions those from a
                    senior level or officer position into project managers which
                    allows our clients to benefit from their extensive
                    leadership skills, gravitas and life experience.
                  </p>
                  <br />
                  <p class="mb-0">
                    The Returners Programme provides clients with experienced
                    business analysts and project managers who are returning to
                    work after a break in their careers.
                  </p>
                  <br />
                  <p class="mb-0">
                    We offer all our consultants a range of initiatives to
                    support and engage them in their transition on site. Learn
                    more about the initiatives we offer in the Oanwtech
                    Consultant Experience brochure.
                  </p>
                  <Link
                    to="/services"
                    class="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                    data-wow-delay="0.9s"
                  >
                    <i class="bi bi-arrow-right"></i>Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessServicesComp;
