import React from "react";
import { Link } from "react-router-dom";

const MoreCaseStudies = () => {
  return (
    <div>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Case Studies </h5>
            <h1 class="mb-0">Read More Case Studies</h1>
          </div>
          <br />
          <center>
            <Link
              to="/casestudy"
              class="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
              data-wow-delay="0.9s"
            >
              Read Client Case Studies
            </Link>
          </center>
        </div>
      </div>
    </div>
  );
};

export default MoreCaseStudies;
