import React from "react";
import Topbar from "../../components/Topbar";
import Hero from "../../components/HeroServices";
import BlogSearchBar from "../../components/blogSearchBar";
import BlogCatergory from "../../components/blogCatergories";
import RecentBlogPosts from "../../components/blogRecentPost";
import BlogTags from "../../components/blogTags";
import Blog5 from "../../components/blogs/blog5Contents";
import Vendor from "../../components/vendor";
import Footer from "../../components/footer";

function BlogContents5() {
  return (
    <>
      <Topbar />

      <Hero title="Blog Grid" main="Blog" page="Blog5" />

      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div class="row g-5">
            <Blog5 />

            <div class="col-lg-4">
              <BlogSearchBar />

              <BlogCatergory />

              <RecentBlogPosts />

              <BlogTags />
            </div>
          </div>
        </div>
      </div>

      <Vendor />

      <Footer />
    </>
  );
}

export default BlogContents5;
