import React from "react";
import Topbar from "../components/Topbar";
import Vendor from "../components/vendor";
import Hero from "../components/Hero";
import Education from "../components/education";
import Partnership from "../components/partnership";
import Recruitment from "../components/recruitments";
import TestimonialComp from "../components/TestimonialComp";
//import CaseStudies from "../components/CaseStudies";
import ContactForm from "../components/contactForm";
import Footer from "../components/footer";

function Community() {
  return (
    <>
      <Topbar />

      <Hero
        title="Early Talent Engagement"
        main="Our-works"
        page="Community-Services"
      />

      <Vendor />

      <Education />

      <Partnership />

      <Recruitment />

      <TestimonialComp />

      {/* <CaseStudies /> */}

      <ContactForm />

      <Footer />
    </>
  );
}

export default Community;
