const AccessibilityComp = () => {
  return (
    <div>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Accessibility</h5>
            <h1 class="mb-0">OanwTech Accessibility Case Study</h1>
          </div>
          <br />
          <center>
            <p>
              Accessibility is a critical aspect of technology that cannot be
              ignored. It refers to the degree to which technology is usable by
              people with disabilities or impairments, such as those who are
              visually, hearing, or physically challenged. As an IT and digital
              solutions company, Oanwtech recognizes the importance of making
              technology accessible to everyone, and is committed to bridging
              people and technology together. Oanwtech understands that making
              technology accessible requires a combination of design,
              development, and testing strategies. In particular, accessibility
              needs to be integrated into the design process from the very
              beginning, rather than being treated as an afterthought. This
              means that designers need to consider the needs of all users,
              including those with disabilities, and incorporate accessibility
              features into the design from the outset. Similarly, developers
              need to use coding practices that facilitate accessibility. This
              means using proper markup and coding practices that help assistive
              technologies, such as screen readers, understand the content and
              structure of a website. Oanwtech recognizes the importance of
              using these practices and adheres to international accessibility
              standards, such as WCAG 2.0, to ensure that their products are
              accessible to all users. Testing is another crucial component of
              accessibility. Oanwtech recognizes the importance of thorough
              testing of their products to ensure they are accessible to all
              users. This includes testing with assistive technologies, such as
              screen readers and other accessibility tools, as well as user
              testing with people who have disabilities or impairments. By doing
              so, Oanwtech ensures that their products are not only accessible,
              but also usable and effective for all users. Overall,
              accessibility is an essential aspect of technology that cannot be
              overlooked. Oanwtech recognizes this fact and is committed to
              bridging people and technology together by creating products that
              are accessible to all users. By incorporating accessibility into
              the design, development, and testing process, Oanwtech ensures
              that their products are inclusive and effective for everyone,
              regardless of their abilities or disabilities.
            </p>
          </center>
        </div>
      </div>
    </div>
  );
};

export default AccessibilityComp;
