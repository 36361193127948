import React from "react";
import Footer from "../../components/footer";
import Hero from "../../components/HeroServices";
import MoreCaseStudies from "../../components/moreCaseStuides";
import OtherServices from "../../components/otherServices";
import Quote from "../../components/quote";
import Topbar from "../../components/Topbar";
import TestimonialComp from "../../components/TestimonialComp";
import AlliancesComp from "../../components/AlliancesComp";
import ContactBtn from "../../components/ContactBtn";
import BackToTop from "../../components/backToTop";
import Vendor from "../../components/vendor";

const Alliances = () => {
  return (
    <div>
      <Topbar />
      <Hero
        title="Alliances"
        extras={
          <div>
            <h4 class="display-9 text-white animated zoomIn">
              We believe in the power of collaboration and partnership. <br />
              That's why we are proud to announce our new alliance program
            </h4>
            <br />
          </div>
        }
        main="services"
        page="Alliances"
      />
      <AlliancesComp />
      <ContactBtn />
      <OtherServices
        titleOne="Technical Services"
        miniTitleOne="Technical"
        linkOne="/technicalservices"
        titleTwo="Tailored Services"
        miniTitleTwo="Tailored"
        linkTwo="/tailoredservices"
        titleThree="Business Services"
        miniTitleThree="Business"
        linkThree="/business-services"
        titleFour="Case Studies"
        miniTitleFour="Case Studies"
        linkFour="/casestudy"
      />
      <TestimonialComp />
      <MoreCaseStudies />
      <Quote />
      <Vendor />
      <Footer />
      <BackToTop />
    </div>
  );
};

export default Alliances;
