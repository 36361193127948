import Topbar from "../components/Topbar";
import Hero from "../components/HeroServices";
import CookieComp from "../components/CookieComp";
import Vendor from "../components/vendor";
import Footer from "../components/footer";

const CookiePolicy = () => {
  return (
    <>
      <Topbar />
      <Hero title="Cookie Policy" page="Cookie-Policy" />
      <CookieComp />
      <Vendor />
      <Footer />
    </>
  );
};

export default CookiePolicy;
