import React from 'react'

function BlogCatergory(){

    return (

        <>
            <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
                <div class="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 class="mb-0">Categories</h3>
                </div>
                <div class="link-animated d-flex flex-column justify-content-start">
                    <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="w"><i class="bi bi-arrow-right me-2"></i>Web Design</a>
                    <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="w"><i class="bi bi-arrow-right me-2"></i>Web Development</a>
                    <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="w"><i class="bi bi-arrow-right me-2"></i>Web Development</a>
                    <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="w"><i class="bi bi-arrow-right me-2"></i>Keyword Research</a>
                    <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="w"><i class="bi bi-arrow-right me-2"></i>Email Marketing</a>
                </div>
            </div>
        </>

    )

}

export default BlogCatergory