import React from "react";
import ContactBtn from "./ContactBtn";
import { Link } from "react-router-dom";

const TailoredServicesComp = () => {
  return (
    <div>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">
              Tailored Services
            </h5>
            <h1 class="mb-0">Oanwtech’s Tailored Services</h1>
          </div>
          <div class="row g-5">
            <div class="col-lg-2">
              <div class="col-lg-3">
                <div class="row g-3">
                  <div class="col-12 wow zoomIn" data-wow-delay="0.4s">
                    <Link class="btn btn-lg btn-primary rounded" to="/services">
                      Overview
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-7  wow zoomIn"
              data-wow-delay="0.9s"
              style={{ minHeight: "350px" }}
            >
              <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                <h4>Building a customised partnership</h4>
                <p class="mb-0">
                  At Oanwtech, we work in partnership with our clients to really
                  understand their organisational challenges and objectives. We
                  know the vast and complex technical landscape that businesses
                  operate in varies enormously, which is why Oanwtech’s model
                  has been designed to be as flexible and agile as possible.
                </p>
                <br />
                <p class="mb-0">
                  Our tailored services provide clients with the specific skills
                  they need, outside of our traditional business and technical
                  service offerings. We can train people in specialised
                  technologies, platforms or working methodologies to suit their
                  organisational objectives. In addition to service specific
                  training, we can also tailor our recruitment processes to help
                  our clients find people with certain skills, such as a second
                  language, or from a minority background to help boost their
                  internal diversity programmes. We always ensure that during
                  the recruitment process, candidates are assessed based on our
                  client’s values to ensure a great cultural fit.
                </p>
                <br />
                <h4>How it works</h4>
                <p class="mb-0">
                  Our tailored training is a collaborative engagement with
                  clients and this allows us to build an end-to-end customised
                  solution. Our approach is all about understanding our clients
                  and their working environment, from the different types of
                  technologies used to identifying the types of delivery
                  methodologies consultants will work in such as Agile or SaFE.
                </p>
                <br />
                <p class="mb-0">
                  We conduct in-depth skills workshops where we establish the
                  core technologies and methodologies required, followed by a
                  gap analysis to determine the level of tailoring that is
                  needed.
                </p>
                <br />
                <p class="mb-0">
                  Identifying the right individuals for the role is key and we
                  do this through a highly targeted recruitment and selection
                  process before any candidate is accepted onto one of our
                  talent programmes. Our clients have the opportunity to meet
                  pre-vetted applicants if they wish prior to them starting
                  their customised training.
                </p>
                <br />
                <Link
                  to="/services"
                  class="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  <i class="bi bi-arrow-right"></i>Learn More
                </Link>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="row g-4">
                <div class="col-12 wow zoomIn" data-wow-delay="0.4s">
                  <Link to="/contact" class="btn btn-lg btn-primary rounded">
                    Contact Us <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
                {/* <div class="col-12 wow zoomIn" data-wow-delay="0.8s">
                  <a
                    class="btn btn-lg btn-primary rounded"
                    href="Download Service Brochure.pdf"
                  >
                    Download Service Brochure
                  </a>
                </div> */}
                {/* <div class="col-12 wow zoomIn" data-wow-delay="1.0s">
                  <a
                    class="btn btn-lg btn-primary rounded"
                    href="Download Consultant Experience Brochure.pdf"
                  >
                    Download Consultant Experience Brochure
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactBtn />
    </div>
  );
};

export default TailoredServicesComp;
