import React from "react";
import { Link } from "react-router-dom";

const ContactBtn = () => {
  return (
    <div>
      <center>
        <Link
          to="/contact"
          class="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
          data-wow-delay="0.9s"
        >
          <i class="bi bi-arrow-right"></i> Contact Us
        </Link>
      </center>
    </div>
  );
};

export default ContactBtn;
