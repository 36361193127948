import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const Vendor = () => {
  return (
    <div>
      {/* Vendor Start  */}
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5 mb-5">
          <div className="bg-white">
          <OwlCarousel className='owl-theme' loop margin={300} nav>
              <img src="img/vendor-1.jpg" alt="" />
              <img src="img/vendor-2.jpg" alt="" />
              <img src="img/vendor-3.jpg" alt="" />
              <img src="img/vendor-4.jpg" alt="" />
              <img src="img/vendor-5.jpg" alt="" />
              <img src="img/vendor-6.jpg" alt="" />
              <img src="img/vendor-7.jpg" alt="" />
              <img src="img/vendor-8.jpg" alt="" />
              <img src="img/vendor-9.jpg" alt="" />
            </OwlCarousel>
          </div>
        </div>
      </div>
      {/* Vendor End  */}
    </div>
  );
};
export default Vendor;
