import React from "react";
import Topbar from "../components/Topbar";
import Hero from "../components/Hero";
import BlogSearchBar from "../components/blogSearchBar";
import BlogCatergory from "../components/blogCatergories";
import RecentBlogPosts from "../components/blogRecentPost";
import BlogTags from "../components/blogTags";
import BlogList from "../components/blogList";
import Vendor from "../components/vendor";
import Footer from "../components/footer";

function Blog() {
  return (
    <>
      <Topbar />
      <Hero title="Blog Page" page="Blog" />
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div class="row g-5">
            <BlogList />
            <div class="col-lg-4">
              <BlogSearchBar />
              <BlogCatergory />
              <RecentBlogPosts />
              <BlogTags />
            </div>
          </div>
        </div>
      </div>
      <Vendor />
      <Footer />
    </>
  );
}

export default Blog;
