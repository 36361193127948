import React from "react";

const AlliancesComp = () => {
    return (
        <div>
            <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="container py-5">
                    <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style= { {maxWidth: "600px"} }>
                        <h5 class="fw-bold text-primary text-uppercase">At Oanwtech, we are proud to be</h5>
                        <h1 class="mb-0">An Approved Training Partner + Workforce Development Partner</h1>
                    </div>
                    <div class="row g-6">
                        <div class="col-lg-12">
                            <div class="row g-5">
                                <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="container py-5">
                    <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style= { {maxWidth: "600px"} }>
                        <h5 class="fw-bold text-primary text-uppercase">collaboration and partnerships</h5>
                        <h1 class="mb-0">We Understand the Power Behind Collaboration</h1>
                        -<p class="mb-0">To create net new talent across specialist skills. That's why we prioritize building strong alliances with our partners in the IT and digital solutions industries.</p>
                    </div>
                    <div class="row g-5">
                        <div class="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                
                                <div class="p-4">
                                    
                                    <h4 class="mb-3">Cloud Platforms</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 wow slideInUp" data-wow-delay="0.6s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                
                                <div class="p-4">
                                    
                                    <h4 class="mb-3">Intelligence and Automation</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                
                                <div class="p-4">
                                    
                                    <h4 class="mb-3">Robotic Process Automation</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 wow slideInUp" data-wow-delay="1.0s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                
                                <div class="p-4">
                                    
                                    <h4 class="mb-3">Digital Process Automation</h4>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                
                                <div class="p-4">
                                    
                                    <h4 class="mb-3">Data Analytics Technology</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 wow slideInUp" data-wow-delay="0.6s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                
                                <div class="p-4">
                                    
                                    <h4 class="mb-3">ERP Platforms</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                
                                <div class="p-4">
                                    
                                    <h4 class="mb-3">Data Cloud Platforms</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 wow slideInUp" data-wow-delay="1.0s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                
                                <div class="p-4">
                                    
                                    <h4 class="mb-3">Intelligent Data Cloud Platforms</h4>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                
                                <div class="p-4">
                                    
                                    <h4 class="mb-3">CRM/BPM Platforms</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 wow slideInUp" data-wow-delay="0.6s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                
                                <div class="p-4">
                                    
                                    <h4 class="mb-3">Data Governance Platforms</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                
                                <div class="p-4">
                                    
                                    <h4 class="mb-3">Insurance Solutions Platform</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 wow slideInUp" data-wow-delay="1.0s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                
                                <div class="p-4">
            
                                    <h4 class="mb-3">GRC Platforms</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <center><h4 class="mb-3">For more information or to learn how <br />Oanwtech can assist your team, <br />get in touch with us today.</h4></center>
        </div>
    );
};

export default AlliancesComp;