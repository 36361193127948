import { Link } from "react-router-dom";

const GraduateApplication = (props) => {
  return (
    <div>
      <div
        class="section-title text-center position-relative pb-3 mb-5 mx-auto"
        style={{ maxWidth: "600px" }}
      >
        <h5 class="fw-bold text-primary text-uppercase">{props.title}</h5>
        <h1 class="mb-0">
          2023 {props.subTitle} Application <br />
          Are Open
        </h1>
      </div>

      <center>
        <h5 class="fw-bold text-primary text-toggle-case">
          Kickstart Your Careers Now!
        </h5>
        <Link
          to="/quote"
          class="btn btn-primary py-3 px-3 mt-3 wow zoomIn"
          data-wow-delay="0.9s"
        >
          Apply Now
        </Link>
      </center>
    </div>
  );
};

export default GraduateApplication;
