import React from "react"

function BlogTags(){

    return (

        <>
        
        <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
            <div class="section-title section-title-sm position-relative pb-3 mb-4">
                <h3 class="mb-0">Tag Cloud</h3>
            </div>
            <div class="d-flex flex-wrap m-n1">
                <a href="/" class="btn btn-light m-1">Design</a>
                <a href="/" class="btn btn-light m-1">Development</a>
                <a href="/" class="btn btn-light m-1">Marketing</a>
                <a href="/" class="btn btn-light m-1">SEO</a>
                <a href="/" class="btn btn-light m-1">Writing</a>
                <a href="/" class="btn btn-light m-1">Consulting</a>
                <a href="/" class="btn btn-light m-1">Design</a>
                <a href="/" class="btn btn-light m-1">Development</a>
                <a href="/" class="btn btn-light m-1">Marketing</a>
                <a href="/" class="btn btn-light m-1">SEO</a>
                <a href="/" class="btn btn-light m-1">Writing</a>
                <a href="/" class="btn btn-light m-1">Consulting</a>
            </div>
        </div>

        </>

    )

}

export default BlogTags