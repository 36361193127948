const TermsAndConditionsComp = () => {
  return (
    <>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ "max-width": "600px;" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">
              Terms And Conditions
            </h5>
            <h3 class="mb-0">
              These Terms & Conditions ("Agreement") govern your use of the
              Oanwtech website, products, and services ("Services").
              <br /> By accessing or using our Services, you agree to be bound
              by this Agreement. If you do not agree to these terms, do not use
              our Services.
            </h3>
          </div>
          <div class="row g-6">
            <div class="col-lg-12">
              <div class="row g-5">
                <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <h4>Definition</h4>
                  <p class="mb-0">
                    "Oanwtech" refers to the IT & Digital Solution company
                    bridging people and technology together.
                  </p>
                  <br />
                  <h4>Agreement</h4>
                  <p class="mb-0">
                    This Agreement constitutes a binding agreement between you
                    and Oanwtech. By using our Services, you agree to comply
                    with all applicable laws and regulations, as well as this
                    Agreement. Oanwtech reserves the right to modify this
                    Agreement at any time without notice.
                  </p>
                  <br />
                  {/* <h4>License and Limitations of Use</h4> */}
                  {/* <br /> */}
                  {/* <p class="mb-0">
                    We may use the personal information we collect for the
                    following purposes:
                  </p>
                  <br />
                  <h6 class="mb-4">
                    <i class="fa fa-reply text-primary me-3"></i>To provide and
                    improve our services to you.
                  </h6>
                  <h6 class="mb-4">
                    <i class="fa fa-reply text-primary me-3"></i>To communicate
                    with you about our services, promotions, and events.
                  </h6>
                  <p class="mb-0">
                    <h6 class="mb-4">
                      <i class="fa fa-reply text-primary me-3"></i>To process
                      your orders and payments.
                    </h6>
                    <h6 class="mb-4">
                      <i class="fa fa-reply text-primary me-3"></i>To respond to
                      your inquiries and requests.
                    </h6>
                    <h6 class="mb-4">
                      <i class="fa fa-reply text-primary me-3"></i>To comply
                      with legal and regulatory requirements.
                    </h6>
                    <h6 class="mb-4">
                      <i class="fa fa-reply text-primary me-3"></i>Access to
                      Your Information and Correction
                    </h6>
                  </p> */}
                  <h4>License and Limitations of Use</h4>
                  <p class="mb-0">
                    Oanwtech grants you a limited, non-exclusive,
                    non-transferable, revocable license to use our Services for
                    personal or commercial purposes. You may not use our
                    Services for any illegal or unauthorized purpose, and you
                    agree to comply with all applicable laws and regulations.
                  </p>
                  <br />
                  <h4>Intellectual Property</h4>
                  <p class="mb-0">
                    All content, trademarks, logos, and other intellectual
                    property on our website are owned by Oanwtech or our
                    licensors. You may not use, reproduce, or distribute any of
                    our intellectual property without our prior written consent.
                  </p>
                  <br />
                  <h4>License</h4>
                  <p class="mb-0">
                    By submitting any content to our Services, you grant
                    Oanwtech a non-exclusive, worldwide, royalty-free, and
                    transferable license to use, reproduce, distribute, and
                    display the content in connection with our Services.
                  </p>
                  <br />
                  <h4>Limitations</h4>
                  <p class="mb-0">
                    Oanwtech reserves the right to remove any content that
                    violates this Agreement or any applicable law or regulation.
                    We also reserve the right to suspend or terminate your
                    access to our Services at any time without notice.
                  </p>
                  <br />
                  <h4>Consent to the Use of Cookies</h4>
                  <p class="mb-0">
                    By using our Services, you consent to the use of cookies on
                    our website. Cookies help us to provide a better user
                    experience by storing information about your preferences and
                    website usage.
                  </p>
                  <br />
                  <h4>Disclaimer</h4>
                  <p class="mb-0">
                    Our Services are provided on an "as is" and "as available"
                    basis, without any warranties or representations of any
                    kind, express or implied. Oanwtech does not warrant that our
                    Services will be uninterrupted or error-free, nor do we
                    warrant the accuracy, completeness, or reliability of any
                    content on our website.
                  </p>
                  <br />
                  <h4>Amendment</h4>
                  <p class="mb-0">
                    Oanwtech reserves the right to modify this Agreement at any
                    time without notice. Your continued use of our Services
                    following any such modification constitutes your acceptance
                    of the modified Agreement.
                  </p>
                  <br />
                  <h4>Jurisdiction</h4>
                  <p class="mb-0">
                    These terms and conditions shall be governed by and
                    construed in accordance with the laws of the jurisdiction in
                    which Oanwtech is located. Any dispute arising out of or in
                    connection with these terms and conditions shall be subject
                    to the exclusive jurisdiction of the courts in that
                    jurisdiction.
                  </p>
                  <br />
                  <h4>Appreciation</h4>
                  <p class="mb-0">
                    Thank you for choosing Oanwtech as your IT & Digital
                    Solution company. We are committed to bridging people and
                    technology together and providing you with the best possible
                    service. If you have any questions or concerns regarding
                    these terms and conditions, please do not hesitate to
                    contact us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditionsComp;
