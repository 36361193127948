import React from 'react'

function Partnership(){

    return (

        <>
        
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{"maxWidth" : "600px"}}>
                <h5 class="fw-bold text-primary text-uppercase">Partnership</h5>
                <h1 class="mb-0">Our Partners Gloabally</h1>
            </div>
            <div class="row g-6">
                <div class="col-lg-12">
                    <div class="row g-5">
                        <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                            <h4>Our Partnership</h4>
                            <p class="mb-0">FDM identified the good work that the Harris Federation are doing, changing the lives of students and giving them a chance to fulfil their potential. The Harris Federation today educates 1 in 44 school students in and around London, with 95% of their secondary academies being judged as ‘Outstanding’ by Ofsted. FDM London has built relationships with several of the Harris Secondary academies in local areas since 2016 to support our commitment to social mobility. Our vision at FDM is to kick-start exciting careers that shape our digital future and through our partnership we are able to showcase the endless opportunities available in technology. </p><br />
							<h4>Our Engagement</h4>
                            <p class="mb-0">In 2017 we invited several secondary students from Harris Bromley Girls into our academy to do Sonic Pi coding in celebration of Girls in ICT Day. In the summer of 2018, we had three Harris students work within our IT Development team for work experience, supporting the revamp of our website. Since 2016, our World of Work day programme offers students an insight into the corporate world: learning about career opportunities, practical tools to be more work ready as well as encouraging their coding skills to support their curriculum learning. Partnering with the Harris Federation to inspire the next generation of digital talent is an important part of our future talent pipeline and to encourage everyone, regardless of background, to take their first step into a successful career in the digital world.</p><br />
							<a class="btn btn-lg btn-primary rounded" href="our-services.html">
                            <i class="bi bi-arrow-right"></i>Learn More</a>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
        </>

    )

}

export default Partnership