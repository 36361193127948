import React from "react";
import { Link } from "react-router-dom";

const OtherServices = (props) => {
  return (
    <div>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Other Services</h5>
            <h1 class="mb-0">
              Datasets of <br />
              Other Services
            </h1>
          </div>
          <div class="row g-5">
            <div class="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-1.jpg" alt="" />
                  <Link
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    to={props.linkOne}
                  >
                    {props.miniTitleOne}
                  </Link>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">{props.titleOne}</h4>
                  <Link to={props.linkOne} class="text-uppercase">
                    Learn More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-2.jpg" alt="" />
                  <Link
                    to={props.linkTwo}
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                  >
                    {props.miniTitleTwo}
                  </Link>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">{props.titleTwo}</h4>
                  <Link to={props.linkTwo} class="text-uppercase">
                    Learn More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <br />
            <div class="col-lg-6 wow slideInUp" data-wow-delay="0.9s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-3.jpg" alt="" />
                  <Link
                    to={props.linkThree}
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                  >
                    {props.miniTitleThree}
                  </Link>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">{props.titleThree}</h4>
                  <Link to={props.linkThree} class="text-uppercase">
                    Learn More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-6 wow slideInUp" data-wow-delay="0.9s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="img/blog-3.jpg" alt="" />
                  <Link
                    to={props.linkFour}
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                  >
                    {props.miniTitleFour}
                  </Link>
                </div>
                <div class="p-4">
                  <h4 class="mb-3">{props.titleFour}</h4>
                  <Link to={props.linkFour} class="text-uppercase">
                    Learn More <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherServices;
