import React from "react";
import CareerPath from "../../components/CareerPath";
import Footer from "../../components/footer";
import Hero from "../../components/HeroServices";
import MoreCaseStudies from "../../components/moreCaseStuides";
import OtherServices from "../../components/otherServices";
import Quote from "../../components/quote";
import TailoredServicesComp from "../../components/tailoredServicesComp";
import Topbar from "../../components/Topbar";
import BackToTop from "../../components/backToTop";
import TestimonialComp from "../../components/TestimonialComp";
import Vendor from "../../components/vendor";

const TailoredServices = () => {
  return (
    <div>
      <Topbar />
      <Hero
        title="Tailored Services"
        extras={
          <center>
            <p
              // href="brochure.pdf"
              class="btn btn-primary py-10 px-5 mt-3 wow zoomIn"
              data-wow-delay="0.9s"
            >
              View our consultant experience brochure
            </p>
          </center>
        }
        main="services"
        page="Tailored services"
      />
      <TailoredServicesComp />
      <CareerPath title="Tailored Services" />
      <OtherServices
        titleOne="Business Services"
        miniTitleOne="Business"
        linkOne="/business-services"
        titleTwo="Technical Services"
        miniTitleTwo="Technical"
        linkTwo="/technicalservices"
        titleThree="Alliances"
        miniTitleThree="Alliances"
        linkThree="/alliances"
        titleFour="Case Studies"
        miniTitleFour="Case Studies"
        linkFour="/casestudy"
      />
      <TestimonialComp />
      <MoreCaseStudies />
      <Quote />
      <Vendor />
      <Footer />
      <BackToTop />
    </div>
  );
};

export default TailoredServices;
