import React from 'react'
import image1 from '../img/blog1sm.jpg'
import image2 from '../img/blog2sm.jpg'
import image3 from '../img/blog3sm.jpg'
import image4 from '../img/blog4sm.jpg'
import image5 from '../img/blog5sm.jpg'

function RecentBlogPosts(){

    const postItems = [

        {idd : 1,content: "5 Reason-Why Responsive Design is Crucial for Your Website", image : image1, link : "BlogContents1"},
        {idd : 2,content: "Branding and Identity Design - The Basics", image : image2, link : "BlogContents2"},
        {idd : 3,content: "Emerging Technologies and Trends Shaping the Future of Industries", image : image3, link : "BlogContents3"},
        {idd : 4,content: "Top 5 Front-End Development Trends for 2023", image : image4, link : "BlogContents4"},
        {idd : 5,content: "The Importance of User Research and Testing in UI UX Design", image : image5, link : "BlogContents5"}


    ]

    return (

        <>

            <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
                <div class="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 class="mb-0">Recent Post</h3>
                </div>
                {postItems.map((post,index) => (
                    <div class="d-flex rounded overflow-hidden mb-3" key={index}>
                        <img class="img-fluid" src={post.image} 
                        style={{"width": "100px", "height": "100px", "objectFit": "cover"}}
                        alt="kknk"/>
                        <a href={`/${post.link}`} class="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">
                            {post.content}
                        </a>
                    </div>
                ))}
                
            </div>

            <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
                <img src={image1} alt="" class="img-fluid rounded"/>
            </div>

        </>

    )

}

export default RecentBlogPosts