import React from "react";

const Facts = () => {
    return (
        <div class="container-fluid facts py-5 pt-lg-0">
        <div class="container py-5 pt-lg-0">
            <div class="row gx-0">
                <div class="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
                    <div class="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{"height":"150px"}}>
                        <div class="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{"width": "60px", "height": "60px"}}>
                            <i class="fa fa-users text-primary"></i>
                        </div>
                        <div class="ps-4">
                            <h5 class="text-white mb-0">Clients in 12 industries</h5>
                            <h1 class="text-white mb-0" data-toggle="counter-up">2,000</h1>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
                    <div class="bg-light shadow d-flex align-items-center justify-content-center p-4" style={{"height":"150px"}}>
                        <div class="bg-primary d-flex align-items-center justify-content-center rounded mb-2" style={{"width": "60px", "height": "60px"}}>
                            <i class="fa fa-check text-white"></i>
                        </div>
                        <div class="ps-4">
                            <h5 class="text-primary mb-0">Universities Recruit</h5>
                            <h1 class="mb-0" data-toggle="counter-up">2,500</h1>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
                    <div class="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{"height":"150px"}}>
                        <div class="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{"width": "60px", "height": "60px"}}>
                            <i class="fa fa-award text-primary"></i>
                        </div>
                        <div class="ps-4">
                            <h5 class="text-white mb-0">Employees Globally</h5>
                            <h1 class="text-white mb-0" data-toggle="counter-up">3,000</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};
export default Facts;