import CareerComp from "../../components/CareerComp";
import CareerPath from "../../components/CareerPath";
import Footer from "../../components/footer";
import Hero from "../../components/Hero";
import Topbar from "../../components/Topbar";

const Career = () => {
  return (
    <>
      <Topbar />
      <Hero title="Careers" page="Careers" />
      <CareerComp />
      <CareerPath />
      <Footer />
    </>
  );
};

export default Career;
