const CookieComp = () => {
  return (
    <div>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Cookie Policy</h5>
            <h2 class="mb-0">
              At Oanwtech, we understand the importance of protecting your
              privacy and ensuring transparency about how we use cookies on our
              website. This cookie policy explains what cookies are, how we use
              them, and your choices regarding their use.
            </h2>
          </div>
          <br />
          <center>
            <h3>Technically Required Cookies</h3>
            <p>
              These cookies are necessary for our website to function properly
              and cannot be disabled. They are typically set in response to user
              actions such as filling out a form or logging in. Without these
              cookies, certain features of our website may not work correctly.
            </p>
            <h3>Google Fonts</h3>
            <p>
              We use Google Fonts to enhance the appearance of our website.
              Google Fonts is a service provided by Google Inc. that allows us
              to use a variety of fonts on our website. Google Fonts may collect
              certain information about your device and browsing behavior.
            </p>
            <h3>Google ReCaptcha</h3>
            <p>
              We use Google ReCaptcha to prevent automated spam and abuse on our
              website. Google ReCaptcha is a service provided by Google Inc.
              that uses advanced risk analysis techniques to distinguish humans
              from bots. Google ReCaptcha may collect certain information about
              your device and browsing behavior.
            </p>
            <h3>Youtube</h3>
            <p>
              We use YouTube to embed videos on our website. YouTube is a video
              sharing platform provided by Google Inc. that allows us to display
              videos on our website. YouTube may collect certain information
              about your device and browsing behavior.
            </p>
            <h3>Usage Analysis and Tracking</h3>
            <p>
              These cookies allow us to analyze how our website is used and
              improve its performance. They also allow us to track user behavior
              and measure the effectiveness of our marketing campaigns.
            </p>
            <h3>Google (Universal) Analytics</h3>
            <p>
              We use Google (Universal) Analytics to analyze how our website is
              used and improve its performance. Google (Universal) Analytics is
              a web analytics service provided by Google Inc. that allows us to
              track user behavior on our website. Google (Universal) Analytics
              may collect certain information about your device and browsing
              behavior.
            </p>
            <h3>Hotjar</h3>
            <p>
              We use Hotjar to analyze how our website is used and improve its
              performance. Hotjar is a web analytics service provided by Hotjar
              Ltd. that allows us to track user behavior on our website. Hotjar
              may collect certain information about your device and browsing
              behavior.
            </p>
            <h3>Marketing Cookies</h3>
            <p>
              These cookies allow us to deliver targeted advertising and measure
              the effectiveness of our marketing campaigns. They are used to
              build a profile of your interests and show you relevant ads on
              other websites.
            </p>
            <h3>Bing Ads</h3>
            <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
              Bing
            </a>
            <p>
              We use Bing Ads to deliver targeted advertising and measure the
              effectiveness of our marketing campaigns. Bing Ads is an
              advertising platform provided by Microsoft Corporation that allows
              us to display ads on Bing and other websites. Bing Ads may collect
              certain information about your device and browsing behavior.
            </p>
            <h3>Doubleclick</h3>
            <a href="https://adssettings.google.com/">Doubleclick</a>
            <p>
              We use Doubleclick to deliver targeted advertising and measure the
              effectiveness of our marketing campaigns. Doubleclick is an
              advertising platform provided by Google Inc. that allows us to
              display ads on Google and other websites. Doubleclick may collect
              certain information about your device and browsing behavior.
            </p>
            <h3>Facebook Custom Audiences</h3>
            <a href="https://www.facebook.com/settings/?tab=ads">
              Facebook Custom Audiences
            </a>
            <p>
              We use Facebook Custom Audiences to deliver targeted advertising
              and measure the effectiveness of our marketing campaigns. Facebook
              Custom Audiences is a marketing platform provided by Facebook Inc.
              that allows us to display ads to users who have already shown an
              interest in our products or services. Facebook Custom Audiences
              may collect certain information about your device and browsing
              behavior.
            </p>
            <h3>Facebook Custom Audiences</h3>
            <p>
              We use Facebook Custom Audiences to deliver targeted advertising
              and measure the effectiveness of our marketing campaigns. Facebook
              Custom Audiences is a marketing platform provided by Facebook Inc.
              that allows us to display ads to users who have already shown an
              interest in our products or services. Facebook Custom Audiences
              may collect certain information about your device and browsing
              behavior.
            </p>
            <h3>Facebook Custom Audiences</h3>
            <p>
              We use Facebook Custom Audiences to deliver targeted advertising
              and measure the effectiveness of our marketing campaigns. Facebook
              Custom Audiences is a marketing platform provided by Facebook Inc.
              that allows us to display ads to users who have already shown an
              interest in our products or services. Facebook Custom Audiences
              may collect certain information about your device and browsing
              behavior.
            </p>
            <h3>Indeed</h3>
            <a href="https://www.indeed.com/legal/ad-cookie-policy">Indeed</a>
            <p>
              We use Indeed to deliver targeted advertising and measure the
              effectiveness of our marketing campaigns. Indeed is a job board
              and recruitment platform that allows us to display job ads to
              potential candidates. Indeed may collect certain information about
              your device and browsing behavior.
            </p>
            <h3>LinkedIn Insight Tag</h3>
            <a href="https://www.linkedin.com/psettings/advertising/actions-that-showed-interest">
              LinkedIn Insight Tag
            </a>
            <p>
              We use LinkedIn Insight Tag to deliver targeted advertising and
              measure the effectiveness of our marketing campaigns. LinkedIn
              Insight Tag is a marketing platform provided by LinkedIn
              Corporation that allows us to display ads to users who have
              already shown an interest in our products or services. LinkedIn
              Insight Tag may collect certain information about your device and
              browsing behavior.
            </p>
          </center>
        </div>
        <div class="mx-5 text-center">
          <h1>Changes to our Cookie Policy</h1>
          <p>
            We may update our Cookie Policy from time to time to reflect changes
            in our cookie practices. We encourage you to review this policy
            periodically for any updates or changes. Contact us If you have any
            questions or concerns about our Cookie Policy, please contact us at
            privacy@oanwtech.com. Last updated: March 4, 2023.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CookieComp;
