/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Topbar = () => {
  return (
    <div>
      {/* <!-- Topbar Start --> */}
      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0">
          <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div
              class="d-inline-flex align-items-center"
              style={{ height: "45px" }}
            >
              <small class="me-3 text-light">
                <i class="fa fa-map-marker-alt me-2"></i> HQ: Etobicoke,
                Ontario, CA
              </small>
              <small class="me-3 text-light">
                <i class="fa fa-address-card"></i> http://vcarrd.me/OanwTech
              </small>
              <small class="text-light">
                <i class="fa fa-envelope-open me-2"></i>info@oanwtech.com
              </small>
              <small class="ml-2 text-light">
                <i class="fa fa-building me-2"></i>Powered by Oanwave Technology
              </small>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div
              className="d-inline-flex align-items-center"
              style={{ height: "45px" }}
            >
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="https://www.twitter.com/oanwtech"
              >
                <i className="fab fa-twitter fw-normal"></i>
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="https://www.facebook.com/oanwtech"
              >
                <i className="fab fa-facebook-f fw-normal"></i>
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="https://www.linkedin.com/company/oanw-technology/"
              >
                <i className="fab fa-linkedin-in fw-normal"></i>
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="https://www.instagram.com/oanwtech"
              >
                <i className="fab fa-instagram fw-normal"></i>
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
                href="https://www.vcarrd.me/oanwtech"
              >
                <i className="fa fa-address-card fw-normal"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
