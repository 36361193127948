import React from "react";
import Nav from "./Nav";
import { Link } from "react-router-dom";

const Hero = (props) => {
  return (
    <div>
      <div className="container-fluid position-relative p-0">
        <Nav />
      </div>
      <div
        class="container-fluid bg-primary py-5 bg-header"
        style={{ marginBottom: "90px" }}
      >
        <div class="row py-5">
          <div class="col-12 pt-lg-5 mt-lg-5 text-center">
            <h1 class="display-4 text-white animated zoomIn">{props.title}</h1>
            {props.extras}
            <br />
            <Link to="/" class="h5 text-white">
              Home
            </Link>
            <i class="far fa-circle text-white px-2"></i>
            <Link to={`/${props.main}`} class="h5 text-white">
              {props.main}
            </Link>
            <i class="far fa-circle text-white px-2"></i>
            <Link to={props.link} class="h5 text-white">
              {props.page}
            </Link>
          </div>
        </div>
      </div>

      {/* Full Screen Search Start  */}
      <div className="modal fade" id="searchModal" tabindex="-1">
        <div className="modal-dialog modal-fullscreen">
          <div
            className="modal-content"
            style={{ background: "rgba(9, 30, 62, .7)" }}
          >
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn bg-white btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex align-items-center justify-content-center">
              <div className="input-group" style={{ maxWidth: "600px" }}>
                <input
                  type="text"
                  className="form-control bg-transparent border-primary p-3"
                  placeholder="Type search keyword"
                />
                <button className="btn btn-primary px-4">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Full Screen Search End */}
    </div>
  );
};

export default Hero;
