import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";

import Home from "./pages/home";
import Clients from "./pages/clients";
// import Awards from "./pages/awards";
import Universities from "./pages/universities";
import Community from "./pages/community";
import Blog from "./pages/blog";
import AboutUs from "./pages/about-us";
import Services from "./pages/services";
import Career from "./pages/careers/career";
import FreeQuote from "./pages/FreeQuote";
import Contact from "./pages/contact";
import Graduate from "./pages/careers/graduate";
import UnderGraduate from "./pages/careers/undergraduate";
import Returners from "./pages/careers/returners";
import ExForces from "./pages/careers/ex-forces";
import BusinessServices from "./pages/services/BusinessServices";
import Alliances from "./pages/services/Alliances";
import CaseStudy from "./pages/services/CaseStudies";
import TailoredServices from "./pages/services/TailoredServices";
import TechnicalServices from "./pages/services/TechnicalServices";
import BlogContents1 from "./pages/blogPages/blog1";
import BlogContents2 from "./pages/blogPages/blog2";
import BlogContents3 from "./pages/blogPages/blog3";
import BlogContents4 from "./pages/blogPages/blog4";
import BlogContents5 from "./pages/blogPages/blog5";
import Oanwave from "./pages/oanwave";
import Visoan from "./pages/visoan";
import FAQs from "./pages/faq";
import Press from "./pages/press";
import CookiePolicy from "./pages/cookie";
import TermsAndConditions from "./pages/terms";
import Privacy from "./pages/privacy";
import Accessibility from "./pages/accesibility";
import Sitemap from "./pages/sitemap";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/career" element={<Career />} />
          <Route path="/business-services" element={<BusinessServices />} />
          <Route path="/alliances" element={<Alliances />} />
          <Route path="/casestudy" element={<CaseStudy />} />
          <Route path="/tailoredservices" element={<TailoredServices />} />
          <Route path="/technicalservices" element={<TechnicalServices />} />
          <Route path="/quote" element={<FreeQuote />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/graduate" element={<Graduate />} />
          <Route path="/undergraduate" element={<UnderGraduate />} />
          <Route path="/returners" element={<Returners />} />
          <Route path="/exforces" element={<ExForces />} />
          <Route path="/universities" element={<Universities />} />
          <Route path="/community" element={<Community />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/clients" element={<Clients />} />
          {/* <Route path="/Awards" element={<Awards />} /> */}
          <Route path="/services" element={<Services />} />
          <Route path="/services" element={<Services />} />
          <Route path="/BlogContents1/" element={<BlogContents1 />} />
          <Route path="/BlogContents2/" element={<BlogContents2 />} />
          <Route path="/BlogContents3/" element={<BlogContents3 />} />
          <Route path="/BlogContents4/" element={<BlogContents4 />} />
          <Route path="/BlogContents5/" element={<BlogContents5 />} />
          <Route path="/oanwave" element={<Oanwave />} />
          <Route path="/visoan" element={<Visoan />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/press" element={<Press />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/accessibility" element={<Accessibility />} />
          <Route path="/sitemap" element={<Sitemap />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
