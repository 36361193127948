import React from 'react'

function Recruitment(){

    return (

        <>
        
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{"max-width": "600px"}}>
                <h5 class="fw-bold text-primary text-uppercase">Recruitments 2022 </h5>
                <h1 class="mb-0">Global Recruitment in Varsities</h1>
            </div><br/><br/><br />
					<div class="container-fluid facts py-5 pt-lg-0">
						<div class="container py-5 pt-lg-0">
							<div class="row gx-0">
								<div class="col-lg-3 wow zoomIn" data-wow-delay="0.3s">
									<div class="bg-light shadow d-flex align-items-center justify-content-center p-4" style={{"height": "100px"}}>
										<div class="bg-primary d-flex align-items-center justify-content-center rounded mb-2" style={{"width": "60px", "height": "60px"}}>
											<i class="fa fa-check text-white"></i>
										</div>
										<div class="ps-4">
											<h5 class="text-primary mb-0">Recruited from</h5>
											<h1 class="mb-0" data-toggle="counter-up">1,500</h1>
											<h5 class="text-primary mb-0">Universities</h5>
										</div>
									</div>
								</div>
								<div class="col-lg-3 wow zoomIn" data-wow-delay="0.1s">
									<div class="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{"height": "100px"}}>
										<div class="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{"width": "60px", "height": "60px"}}>
											<i class="fa fa-users text-primary"></i>
										</div>
										<div class="ps-4">
											<h5 class="text-white mb-0">Partnered w/</h5>
											<h1 class="text-white mb-0" data-toggle="counter-up">150</h1>
											<h5 class="text-white mb-0">Universities</h5>
										</div>
									</div>
								</div>
								<div class="col-lg-3 wow zoomIn" data-wow-delay="0.3s">
									<div class="bg-light shadow d-flex align-items-center justify-content-center p-4" style={{"height": "100px"}}>
										<div class="bg-primary d-flex align-items-center justify-content-center rounded mb-2" style={{"width": "60px", "height": "60px"}}>
											<i class="fa fa-check text-white"></i>
										</div>
										<div class="ps-4">
											<h1 class="mb-0" data-toggle="counter-up">2,500</h1>
											<h5 class="text-primary mb-0">Applicants</h5>
										</div>
									</div>
								</div>
								<div class="col-lg-3 wow zoomIn" data-wow-delay="0.6s">
									<div class="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{"height": "100px"}}>
										<div class="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{"width": "60px", "height": "60px"}}>
											<i class="fa fa-award text-primary"></i>
										</div>
										<div class="ps-4">
											<h5 class="text-white mb-0">launched over</h5>
											<h1 class="text-white mb-0" data-toggle="counter-up">1,500</h1>
											<h5 class="text-white mb-0">Careers</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
		</div>
	</div>
        
        </>

    )

}

export default Recruitment