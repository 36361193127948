import React from "react";
import { Link } from "react-router-dom";

import services from "../serviceData.json";

const ServiceItems = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
          <h1 className="mb-0">
            Custom IT Solutions for Your Successful Business
          </h1>
        </div>
        <div className="row g-5">
          {services.services.map((service) => (
            <div
              className="col-lg-4 col-md-6 wow zoomIn"
              data-wow-delay="0.3s"
              key={service.id}
            >
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <i dangerouslySetInnerHTML={{ __html: service.icon }}></i>
                </div>
                <h4 className="mb-3">{service.title}</h4>
                <p className="m-0">{service.subtitle}</p>
                <Link
                  className="btn btn-lg btn-primary rounded"
                  to={`/${service.link}`}
                >
                  <i dangerouslySetInnerHTML={{ __html: service.linkIcon }}></i>
                </Link>
              </div>
            </div>
          ))}
          <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div class="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
              <h3 class="text-white mb-3">Call Us For Quote</h3>
              <p class="text-white mb-3">
                Our team of experienced professionals is ready to provide you
                with a customized quote tailored to your specific needs. Contact
                us now to get started!
              </p>
              <h2 class="text-white mb-0">1437-429-7769</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceItems;
