import React from "react";

const TeamComp = () => {
  return (
    <div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              Team Members
            </h5>
            <h1 className="mb-0">Professionals Ready to Help Your Business</h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden">
                  {/* <img className="img-fluid w-100" src="img/Admin.jpg" alt="" /> */}
                  <img className="img-fluid w-100" src="img/Abel.png" alt="" />
                  <div className="team-social">
                    {/* <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="#test"
                    >
                      <i className="fab fa-twitter fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="#test"
                    >
                      <i className="fab fa-facebook-f fw-normal"></i>
  </a>*/}
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://vcarrd.me/oanwtech"
                    >
                      <i className="fa fa-address-card fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://www.linkedin.com/in/abel-okoh-b7029ab1/?originalSubdomain=ca "
                    >
                      <i className="fab fa-linkedin-in fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div className="text-center py-4">
                  <h4 className="text-primary">Abel O</h4>
                  <p className="text-uppercase m-0">CO-FOUNDER</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="img/Albert.png"
                    alt=""
                  />
                  <div className="team-social">
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://twitter.com/okohcsunday"
                    >
                      <i className="fab fa-twitter fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://web.facebook.com/timet.concepts"
                    >
                      <i className="fab fa-facebook-f fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://vcarrd.me/sundayokoh"
                    >
                      <i className="fa fa-address-card fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://www.linkedin.com/in/sunday-chukwuyem-okoh-16733266/"
                    >
                      <i className="fab fa-linkedin-in fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div className="text-center py-4">
                  <h4 className="text-primary">Okoh C. Sunday</h4>
                  <p className="text-uppercase m-0">
                    TALENT/ACQUISITION MANAGER
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden">
                  <img className="img-fluid w-100" src="img/OJohn.jpg" alt="" />
                  <div className="team-social">
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://twitter.com/coding_wizard__"
                    >
                      <i className="fab fa-twitter fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://www.facebook.com/profile.php?id=100089883405119"
                    >
                      <i className="fab fa-facebook-f fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="http://vcarrd.me/oladele"
                    >
                      <i className="fa fa-address-card fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://www.linkedin.com/in/john-oladele-109ba1246/"
                    >
                      <i className="fab fa-linkedin-in fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div className="text-center py-4">
                  <h4 className="text-primary">Oladele John</h4>
                  <p className="text-uppercase m-0">UI/UX & WEB DEVELOPER</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden">
                  <img className="img-fluid w-100" src="img/Jude.png" alt="" />
                  <div className="team-social">
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://twitter.com/JudeOscar4"
                    >
                      <i className="fab fa-twitter fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://vcarrd.me/judeolajumoke"
                    >
                      <i className="fa fa-address-card fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://www.instagram.com/Jude_Oscar12/"
                    >
                      <i className="fab fa-instagram fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://www.linkedin.com/in/jude-olajumoke-002426246/"
                    >
                      <i className="fab fa-linkedin-in fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div className="text-center py-4">
                  <h4 className="text-primary">Jude Olajumoke</h4>
                  <p className="text-uppercase m-0">WEB DEVELOPER</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="img/JohnOny.png"
                    alt=""
                  />
                  <div className="team-social">
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://twitter.com/_Johnex"
                    >
                      <i className="fab fa-twitter fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://vcarrd.me/johncodes"
                    >
                      <i className="fa fa-address-card fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://instagram.com/__johnex?igshid=ZDdkNTZiNTM="
                    >
                      <i className="fab fa-instagram fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://www.linkedin.com/in/john-onyeibor-324215260"
                    >
                      <i className="fab fa-linkedin-in fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div className="text-center py-4">
                  <h4 className="text-primary">John Onyeibor</h4>
                  <p className="text-uppercase m-0">WEB DEVELOPER</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="img/Muneerat.png"
                    alt=""
                  />
                  <div className="team-social">
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://twitter.com/NeeratOkikiola"
                    >
                      <i className="fab fa-twitter fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://vcarrd.me/muneeratOkikiola"
                    >
                      <i className="fa fa-address-card fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://www.instagram.com/iam_kie_kie/"
                    >
                      <i className="fab fa-instagram fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://www.linkedin.com/in/muneerat-a-04810b263/"
                    >
                      <i className="fab fa-linkedin-in fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div className="text-center py-4">
                  <h4 className="text-primary">Muneerat Okikiola</h4>
                  <p className="text-uppercase m-0">PROJECT MANAGER</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden">
                  <img className="img-fluid w-100" src="img/Isaac.png" alt="" />
                  <div className="team-social">
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://twitter.com/Dfoks_Media?t=9RJHBBbPCubbHdf-RTfcDA&s=09"
                    >
                      <i className="fab fa-twitter fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://vcarrd.me/isaacolufokunbi"
                    >
                      <i className="fa fa-address-card fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://instagram.com/isaac_olufokunbi?igshid=ZDdkNTZiNTM="
                    >
                      <i className="fab fa-instagram fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-lg btn-primary btn-lg-square rounded"
                      href="https://www.linkedin.com/in/isaac-olufokunbi-3b8517245"
                    >
                      <i className="fab fa-linkedin-in fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div className="text-center py-4">
                  <h4 className="text-primary">Isaac Olufokunbi</h4>
                  <p className="text-uppercase m-0">Graphic Designer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamComp;
