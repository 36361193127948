import { Link } from "react-router-dom";

const CareerComp = () => {
  return (
    <div>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Careers</h5>
            <h1 class="mb-0">
              Check Our Career <br />
              Programmes
            </h1>
          </div>
          <div class="row g-6">
            <div class="col-lg-5">
              <div class="row g-5">
                <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <div
                    class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i class="fa fa-cubes text-white"></i>
                  </div>
                  <h4>I Am A Graduate</h4>
                  <p class="mb-0">
                    Bridging the gap between university and the professional
                    workplace
                  </p>
                  <Link class="btn btn-lg btn-primary rounded" to="/graduate">
                    <i class="bi bi-arrow-right"></i> Find Out More
                  </Link>
                </div>
                <div class="col-12 wow zoomIn" data-wow-delay="0.6s">
                  <div
                    class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i class="fa fa-award text-white"></i>
                  </div>
                  <h4>I Am An Undergraduate</h4>
                  <p class="mb-0">
                    Opportunities for individuals who have taken an extended
                    break in their career
                  </p>
                  <Link
                    class="btn btn-lg btn-primary rounded"
                    to="/undergraduate"
                  >
                    <i class="bi bi-arrow-right"></i> Find Out More
                  </Link>
                </div>
              </div>
            </div>
            {/* <div
              class="col-lg-4  wow zoomIn"
              data-wow-delay="0.9s"
              style={{ minHeight: "350px" }}
            >
              <div class="position-relative h-100">
                <img
                  class="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.1s"
                  src="img/feature.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            {} */}
            <div class="col-lg-6">
              <div class="row g-5">
                <div class="col-12 wow zoomIn" data-wow-delay="0.4s">
                  <div
                    class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i class="fa fa-users-cog text-white"></i>
                  </div>
                  <h4>I Am A Returner</h4>
                  <p class="mb-0">
                    Opportunities for individuals who have taken an extended
                    break in their career
                  </p>
                  <Link class="btn btn-lg btn-primary rounded" to="/returners">
                    <i class="bi bi-arrow-left"></i> Find Out More
                  </Link>
                </div>
                <div class="col-12 wow zoomIn" data-wow-delay="0.8s">
                  <div
                    class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i class="fa fa-phone-alt text-white"></i>
                  </div>
                  <h4>I Am An Ex-Forces</h4>
                  <p class="mb-0">
                    Providing a smooth transition into the corporate world
                  </p>
                  <Link class="btn btn-lg btn-primary rounded" to="/exforces">
                    <i class="bi bi-arrow-left"></i> Find Out More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerComp;
