import React from "react";
import { Link } from "react-router-dom";
import logo from "../img/oanwtech-logo-1204.png";

const Footer = () => {
  return (
    <>
      <>
        <div
          class="container-fluid bg-dark text-light mt-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="container">
            <div class="row gx-5">
              <div class="col-lg-4 col-md-6 footer-about">
                <div class="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                  <a href="index.html" class="navbar-brand">
                    <h1 class="m-0 text-white">
                      <img class="w-2" src={logo} alt="oanwtech" />
                      OanwTech
                    </h1>
                  </a>
                  <p class="mt-3 mb-4">
                    We are renowned for our Dynamic Careers Programme, which
                    provides graduates, undergraduates, returners and ex-forces
                    personnel to work with award-winning business and technical
                    training, before being deployed as consultants with our
                    industry-leading clients. Our ideal candidate could be a
                    self-starter, ambitious, and engaged fellow who is willing
                    to grow this company with his/her creativity and
                    productivity.
                  </p>
                  <form action="">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control border-white p-3"
                        placeholder="Your Email"
                      />
                      <button class="btn btn-dark">Sign Up</button>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-lg-8 col-md-6">
                <div class="row gx-5">
                  <div class="col-lg-4 col-md-12 pt-5 mb-5">
                    <div class="section-title section-title-sm position-relative pb-3 mb-4">
                      <h3 class="text-light mb-0">Get In Touch</h3>
                    </div>
                    <div class="d-flex mb-2">
                      <i class="bi bi-geo-alt text-primary me-2"></i>
                      <p class="mb-0"> HQ: Etobicoke, Ontario, CA</p>
                    </div>
                    <div class="d-flex mb-2">
                      <i class="bi bi-envelope-open text-primary me-2"></i>
                      <p class="mb-0">info@oanwtech.com</p>
                    </div>
                    <i class=""></i>
                    <div class="d-flex mb-2">
                      <i class="fa fa-address-card"></i>
                      <a href="https://vcarrd.me/oanwTech">
                        <p class="mb-0">Vcarrd.me/OanwTech</p>
                      </a>
                    </div>
                    <div class="d-flex mb-2">
                      <i class="bi bi-telephone text-primary me-2"></i>
                      <p class="mb-0">437-429-7769</p>
                    </div>
                    <div class="d-flex mt-4">
                      <a
                        class="btn btn-primary btn-square me-2"
                        href="https://www.twitter.com/oanwtech"
                      >
                        <i class="fab fa-twitter fw-normal"></i>
                      </a>
                      <a
                        class="btn btn-primary btn-square me-2"
                        href="https://www.facebook.com/oanwtech"
                      >
                        <i class="fab fa-facebook-f fw-normal"></i>
                      </a>
                      <a
                        class="btn btn-primary btn-square me-2"
                        href="https://www.linkedin.com/company/oanw-technology/"
                      >
                        <i class="fab fa-linkedin-in fw-normal"></i>
                      </a>
                      <a
                        class="btn btn-primary btn-square"
                        href="https://www.instagram.com/oanwtech"
                      >
                        <i class="fab fa-instagram fw-normal"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                    <div class="section-title section-title-sm position-relative pb-3 mb-4">
                      <h3 class="text-light mb-0">Services</h3>
                    </div>
                    <div class="link-animated d-flex flex-column justify-content-start">
                      <Link class="text-light mb-2" to="/">
                        <i class="bi bi-arrow-right text-primary me-2"></i>Home
                      </Link>
                      <Link class="text-light mb-2" to="/aboutus">
                        <i class="bi bi-arrow-right text-primary me-2"></i>Our
                        Work
                      </Link>
                      <Link class="text-light mb-2" to="/services">
                        <i class="bi bi-arrow-right text-primary me-2"></i>Our
                        Services
                      </Link>
                      <Link class="text-light mb-2" to="/career">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Careers
                      </Link>
                      <Link class="text-light mb-2" to="/Blog">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Latest Blog
                      </Link>
                      {/* <Link class="text-light mb-2" to="/faqs">
                        <i class="bi bi-arrow-right text-primary me-2"></i>FAQs
                      </Link> */}
                      <Link class="text-light" to="/contact">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Contact Us
                      </Link>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                    <div class="section-title section-title-sm position-relative pb-3 mb-4">
                      <h3 class="text-light mb-0">Supports</h3>
                    </div>
                    <div class="link-animated d-flex flex-column justify-content-start">
                      {/* <Link class="text-light mb-2" to="/press">
                        <i class="bi bi-arrow-right text-primary me-2"></i>Press
                      </Link> */}
                      <Link class="text-light mb-2" to="/cookie-policy">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Cookie Policy
                      </Link>
                      <Link class="text-light mb-2" to="/terms-and-conditions">
                        <i class="bi bi-arrow-right text-primary me-2"></i>Terms
                        & Conditions
                      </Link>
                      <Link class="text-light mb-2" to="/privacy">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Privacy
                      </Link>
                      <Link class="text-light mb-2" to="/accessibility">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Accessibility
                      </Link>
                      {/* <Link class="text-light" to="/sitemap">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Sitemap
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="container-fluid text-white"
          style={{ background: "#061429" }}
        >
          <div class="container text-center">
            <div class="row justify-content-end">
              <div class="col-lg-8 col-md-6">
                <div
                  class="d-flex align-items-center justify-content-center"
                  style={{ height: "75px" }}
                >
                  <p class="mb-0">
                    &copy;{" "}
                    <a class="text-white border-bottom" href="#test">
                      Oanwtech
                    </a>
                    . All Rights Reserved.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <>
        <div
          class="container-fluid text-white"
          style={{ background: "#061429" }}
        >
          <div class="container text-center">
            <div class="row justify-content-end">
              <div class="col-lg-8 col-md-6">
                <div
                  class="d-flex align-items-center justify-content-center"
                  style={{ height: "75px" }}
                >
                  <p class="mb-0">
                    &copy; 2023 Powered by{" "}
                    <a
                      class="text-white border-bottom"
                      href="https://oanwave.com"
                    >
                      Oanwave Technology
                    </a>
                    . All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Footer;
