import React from "react";
import BackToTop from "../components/backToTop";
import Footer from "../components/footer";
import Hero from "../components/Hero";
import Quote from "../components/quote";
import Topbar from "../components/Topbar";
import Vendor from "../components/vendor";

const FreeQuote = () => {
  return (
    <div>
      <Topbar />
      <Hero title="Free Quote" page="Quote" />
      <Quote />
      <Vendor />
      <Footer />
      <BackToTop />
    </div>
  );
};

export default FreeQuote;
