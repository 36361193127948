import React from "react";
import { Link } from "react-router-dom";
import image1 from "../img/blog1sm.jpg";
import image2 from "../img/blog2sm.jpg";
import image3 from "../img/blog3sm.jpg";
import image4 from "../img/blog4sm.jpg";
import image5 from "../img/blog5sm.jpg";

function BlogList() {
  const blog_content = [
    {
      id: 1,
      image: image1,
      imageDescription: "Web Design",
      user: "Oladele John",
      date: "03 Mar, 2023",
      link: "blogContents1",
      text: "5 Reason-Why Responsive Design is Crucial for Your Website",
      linkText: "Read More",
    },
    {
      id: 2,
      image: image2,
      imageDescription: "Web Design",
      user: "John Onyeibor",
      date: "03 Mar, 2023",
      link: "blogContents2",
      text: "Branding and Identity Design - The Basics",
      linkText: "Read More",
    },
    {
      id: 3,
      image: image3,
      imageDescription: "Web Design",
      user: "Jude Olajumoke",
      date: "03 Mar, 2023",
      link: "blogContents3",
      text: "Emerging Technologies and Trends Shaping the Future of Industries",
      linkText: "Read More",
    },
    {
      id: 4,
      image: image4,
      imageDescription: "Web Design",
      user: "Sunday C. Okoh",
      date: "03 Mar, 2023",
      link: "blogContents4",
      text: "Top 5 Front-End Development Trends for 2023",
      linkText: "Read More",
    },
    {
      id: 5,
      image: image5,
      imageDescription: "Web Design",
      user: "Isaac Foks",
      date: "03 Mar, 2023",
      link: "blogContents5",
      text: "The Importance of User Research and Testing in UI UX Design",
      linkText: "Read More",
    },
  ];

  return (
    <>
      <div class="col-lg-8">
        <div class="row g-5">
          {blog_content.map((post, index) => (
            <div
              class="col-md-6 wow slideInUp"
              data-wow-delay="0.1s"
              key={index}
            >
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src={post.image} alt="" />
                  <Link
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    href="/"
                  >
                    {post.imageDescription}
                  </Link>
                </div>
                <div class="p-4">
                  <div class="d-flex mb-3">
                    <small class="me-3">
                      <i class="far fa-user text-primary me-2"></i>
                      {post.user}
                    </small>
                    <small>
                      <i class="far fa-calendar-alt text-primary me-2"></i>01
                      Jan, 2045
                    </small>
                  </div>
                  <h4 class="mb-3">{post.header}</h4>
                  <p>{post.text}</p>
                  <Link class="text-uppercase" to={`/${post.link}`}>
                    {post.linkText}
                    <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
          {/* <div class="col-12 wow slideInUp" data-wow-delay="0.1s">
            <nav aria-label="Page navigation">
              <ul class="pagination pagination-lg m-0">
                <li class="page-item disabled">
                  <a class="page-link rounded-0" href="/" aria-label="Previous">
                    <span aria-hidden="true">
                      <i class="bi bi-arrow-left"></i>
                    </span>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="/">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="/">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="/">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link rounded-0" href="/" aria-label="Next">
                    <span aria-hidden="true">
                      <i class="bi bi-arrow-right"></i>
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default BlogList;
